import React from 'react'

// Libraries
import { Col, Container, Navbar, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FooterStyle01 from "../../Components/Footers/FooterStyle01"

// Components
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from '../../Components/Header/Header'
// Data

const ListData = [
  {
    content: "This policy applies where we are acting as a data controller with respect to the personal data.",
  },
  {
    content: "By using our website and agreeing to this policy, you consent to our use of cookies in accordance.",
  },
  {
    content: "Our website incorporates privacy controls which affect how we will process your personal data.",
  },
  {
    content: "Theme advantages are pixel perfect design clear code delivered.",
  },
]


const PrivacyPolicy = (props) => {
  document.title = "Privacy Policy - my kitchen jobs";

  return (
    <>
      <Header btn1={true} topSpace={{ desktop: true }} type="always-fixed border-b border-gray">
        <HeaderNav bg1={'#1c84fd'} bg="#1c84fd" fluid="fluid" theme="light" menu="light" className="px-[75px] py-[0px] md:py-[12px] sm:py-[15px] xs:py-[10px] md:pl-0 md:pr-[15px]" containerClass="px-0 sm:px-1 md:px-4 lg:px-8">
          <Col xs={5} className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0 xs:!pr-0">
            <Link aria-label="link for" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                {window.innerWidth > 960 ? <img style={{ minWidth: '250px', maxHeight: 'fit-content' }} className="default-logo default-logo min-w-[0px] md:min-w-[260px]" loading="lazy" src='/assets/img/logoblur.jpeg' alt='logo' /> : ''}
                <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/Skype_Picture_2024_05_23T10_19_30_008Z-removebg-preview.png' alt='logo' />
                {window.innerWidth <= 960 ? <img style={{ minWidth: '200px', maxHeight: 'fit-content', margin: '15px 0' }} className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/logoblur.jpeg' alt='logo' /> : ''}
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle className="text-white order-last md:ml-[17px] md:px-[15px] xs:px-0 border-none">
            <span className="bg-white navbar-toggler-line"></span>
            <span className="bg-white navbar-toggler-line"></span>
            <span className="bg-white navbar-toggler-line"></span>
            <span className="bg-white navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="col-auto col-lg-8 justify-end menu-order px-lg-0 md:!px-0 text-white">
            <Menu {...props} />
          </Navbar.Collapse>
          {/* {localStorage.getItem('token') && <Col xs={5} className="xs:!pl-[0px] col-auto col-lg-2 text-end pe-0 font-size-0">
                        <HeaderCart className="xs:pl-[0px]" />
                    </Col>} */}
        </HeaderNav>
      </Header>
      <section className="py-[140px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
        <Container>
          <div className='text-center mb-12 text-black'>
            <h4>Privacy Policy</h4>
          </div>
          <Row className="items-center justify-center">
            <Col lg={9} md={12} className="col-12">
              <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">General information</h6>
              <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry’s standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap electronic typesetting, essentially unchanged.</p>
              <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Lorem ipsum has been the industry’s standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem ipsum is simply dummy text of the printing and typesetting industry.</p>
              <p className="mb-0">&nbsp;</p>
              <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">Policy introduction</h6>
              <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">It has survived not only five centuries, but also the leap electronic typesetting, essentially unchanged. Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry’s standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
              {/* <Lists theme="list-style-06" data={ListData} animationDelay={0} /> */}
              <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Lorem ipsum has been the industry’s standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
              <p className="mb-0">&nbsp;</p>
              <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">Providing your personal data to others</h6>
              <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry’s standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
              <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Lorem ipsum has been the industry’s standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem ipsum is simply dummy text of the printing and typesetting industry.</p>
              <p className="mb-0">&nbsp;</p>
              <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">Personally Identifiable Information</h6>
              <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release.</p>
            </Col>
          </Row>
        </Container>
      </section>


      <FooterStyle01 theme="dark" className="bg-[#262b35] text-slateblue" />

    </>
  )
}

export default PrivacyPolicy