import React, { useEffect, useRef, useState } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { m } from 'framer-motion';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Components
import { fadeIn } from '../../Functions/GlobalAnimations';
import { resetForm, sendEmail } from "../../Functions/Utilities";
import Buttons from '../../Components/Button/Buttons'
import { Checkbox, Input, TextArea } from '../../Components/Form/Form'
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from "../../Components/Header/Header";
import { Navbar } from 'react-bootstrap';
import FooterStyle01 from '../../Components/Footers/FooterStyle01';
import { toast } from 'react-toastify';
import { listCities } from '../api/cities';
import { createUser } from '../api/users';
const UserSignUp = (props) => {
    const Router = useNavigate()
    const [cityData, setCityData] = useState([])

    const handleSubmit = async (values) => {
        const result = await createUser(values)
        if (!result.error) {
            toast.success("Information submitted successfully" || result.title)
            resetForm()
            Router("/")
        } else {
            toast.error(result.title)
        }
    }
    const getCities = async () => {
        const result = await listCities()
        if (!result.error) {
            setCityData(result.citiesData || [])
        } else {
            toast.error(result.title)
        }
    }
    useEffect(() => {
        getCities()
    }, [])

    const InitailVal = {
        name: '',
        email: '',
        phone_no: '',
        location: '',
        password: '',
        type: '',
        gumasta_no: '',
    }
    const validationSchema = {
        name: Yup.string().required("Please enter your name"),
        email: Yup.string().email("Plese enter valid email").required("Please enter your email"),
        phone_no: Yup.string().min(10).max(10).required("Please enter your phone number"),
        location: Yup.string().required("Please select your location"),
        password: Yup.string().required("Please enter your password"),
        confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required("Please confirm your password"),
        type: Yup.string().required("Please select type"),
        // gumasta_no: Yup.string().when("type", (type) => {
        //     if (type == 'business') {
        //         return Yup.string().required("Please enter your Gumasta licence number")
        //     }
        // })
    }
    document.title = "Sign Up - my kitchen jobs";

    return (
        <>
             <Header btn1={true} topSpace={{ desktop: true }} type="always-fixed border-b border-gray">
                <HeaderNav bg1={'#1c84fd'} bg="#1c84fd" fluid="fluid" theme="light" menu="light" className="px-[75px] py-[0px] md:py-[12px] sm:py-[15px] xs:py-[10px] md:pl-0 md:pr-[15px]" containerClass="px-0 sm:px-1 md:px-4 lg:px-8">
                    <Col xs={5} className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0 xs:!pr-0">
                        <Link aria-label="link for" className="flex items-center" to="/">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                {window.innerWidth > 960 ? <img style={{ minWidth: '250px', maxHeight: 'fit-content' }} className="default-logo default-logo min-w-[0px] md:min-w-[260px]" loading="lazy" src='/assets/img/logoblur.jpeg' alt='logo' /> : ''}
                                <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/Skype_Picture_2024_05_23T10_19_30_008Z-removebg-preview.png' alt='logo' />
                                {window.innerWidth <= 960 ? <img style={{ minWidth: '200px', maxHeight: 'fit-content', margin:'15px 0' }} className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/logoblur.jpeg' alt='logo' /> : ''}
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <Navbar.Toggle className="text-white order-last md:ml-[17px] md:px-[15px] xs:px-0 border-none">
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse className="col-auto col-lg-8 justify-end menu-order px-lg-0 md:!px-0 text-white">
                        <Menu {...props} />
                    </Navbar.Collapse>
                    {/* {localStorage.getItem('token') && <Col xs={5} className="xs:!pl-[0px] col-auto col-lg-2 text-end pe-0 font-size-0">
                        <HeaderCart className="xs:pl-[0px]" />
                    </Col>} */}
                </HeaderNav>
            </Header>
            <m.section className="font-serif py-[130px] overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px]"{...fadeIn}>
                <Container>
                    <Row className="justify-center checkout-page">
                        <Col xl={{ span: 12, offset: 1 }} md={6} className="lg:px-[30px] md:px-[15px]">
                            <h6 className="font-serif font-medium  pinkText">User Sign Up</h6>
                            <div style={{ boxShadow: '0 0 15px rgba(0, 0, 0, 0.08)' }} className="bg-white p-16 border-mediumgray lg:mt-[35px] md:p-10">
                                <Formik
                                    initialValues={InitailVal}
                                    validationSchema={Yup.object().shape(
                                        validationSchema
                                    )}
                                    onSubmit={async (values, actions) => {
                                        handleSubmit(values, actions)
                                    }}
                                >
                                    {({ isSubmitting, getFieldProps, status, handleChange, values, errors, touched, getFieldMeta }) => (

                                        <Form>
                                            <Row className="justify-start checkout-page">
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                    <Input showErrorMsg={false} name="name" type="text" labelClass="mb-[20px]" label={<div className="mb-[15px]">Name <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your name" />
                                                </Col>
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                    <Input showErrorMsg={false} name="email" type="email" labelClass="mb-[20px]" label={<div className="mb-[15px]">Email <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your email" />
                                                </Col>
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                    <Input showErrorMsg={false} name="phone_no" type="text" labelClass="mb-[20px]" label={<div className="mb-[15px]">Phone number <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your phone number" />
                                                </Col>
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                    <label className="mb-[15px]" htmlFor="contry">State <span className="text-red-500">*</span></label>
                                                    <select name="location" id="contry-2" className={`${getFieldMeta(values.location).error && getFieldMeta(values.location).error.type && getFieldMeta(values.location).touched ? " dangerColorRed " : ''} py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial] bg-white`}
                                                        onChange={handleChange}
                                                    >
                                                        <option value=""> Current State</option>
                                                        {
                                                            cityData.map(v => {
                                                                return <option value={v._id} key={v._id}> {v.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </Col>
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                    <Input showErrorMsg={false} name="password" type="password" labelClass="mb-[20px]" label={<div className="mb-[15px]">Password <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your password" />
                                                </Col>
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                    <Input showErrorMsg={false} name="confirm_password" type="password" labelClass="mb-[20px]" label={<div className="mb-[15px]">Confirm Password <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your confirm password" />
                                                </Col>
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">

                                                    <label className="mb-[15px]" htmlFor="contry">Type <span className="text-red-500">*</span></label>
                                                    <select showErrorMsg={false} name="type" id="contry-2" className={`${getFieldMeta(values.type).error && getFieldMeta(values.type).error.type && getFieldMeta(values.type).touched ? " dangerColorRed " : ''}  py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial] bg-white`}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Please select type</option>

                                                        <option value='individual'>Individual </option>
                                                        <option value='business'> Business</option>

                                                    </select>
                                                </Col>
                                            
                                            </Row>
                                            <div className='flex'>
                                            <Buttons ariaLabel="register" type="submit" className="btnImp btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" themeColor="#fff" color="#fff" size="lg" title="Register" />

                                            <Link className={`nav-link ml-2 `} to={'/login'}>{
                                                    <button class=" btnImp text-black rounded-[50px] bg-transparent px-5 py-3 flex items-center space-x-2 transition duration-300">
                                                        <span className="text-white font-medium text-[13px]">SIGN In</span>
                                                        <span class="text-white text-[13px] transform transition-transform duration-300 group-hover:translate-x-1 fas fa-arrow-left"></span>
                                                    </button>
                                                }</Link>
                                            </div>
                                          
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </m.section>
            <FooterStyle01 theme="dark" className="text-slateblue bg-[#262b35]" />
        </>
    )
}

export default UserSignUp
