import React, { useContext, useEffect, useRef, useState } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { m } from 'framer-motion';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// Components
import Buttons from '../../Components/Button/Buttons'
import { Checkbox, Input, TextArea } from '../../Components/Form/Form'
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from "../../Components/Header/Header";
import { toast } from 'react-toastify';
import { listCities } from '../../Pages/api/cities';
import { AuthContext } from '../../contextApi/authContext';
import { getUserDetails, resend_otp, updateUser } from '../../Pages/api/users';
import { resetForm } from '../../Functions/Utilities';
import { getUserJobs } from '../../Pages/api/jobs';
import JobCard from '../IconWithText/JobCard';
const MyJobs = (props) => {
    const Router = useNavigate() 
    const { userData } = useContext(AuthContext)
    const [jobs, setJobs] = useState([])
    const [open, setOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [statusOpen, setStatusOpen] = useState(false)
    const [selectedJob, setSelectedJob] = useState({})
    const getJobs = async () => {
        const result = await getUserJobs(userData.id)
        if (!result.error) {
            setJobs(result.jobsData)
        } else {
            toast.error(result.title)
        }
    }
    const closePopup = () => {
        setOpen(false)
        getJobs()
    }
    const closeEditPopup = () => {
        setEditOpen(false)
        setSelectedJob({})
        getJobs()
    }
    const closeStatusPopup = () => {
        setStatusOpen(false)
        setSelectedJob({}) 
        getJobs()
    }
    useEffect(() => {
        if (userData && userData.id) {
            getJobs()
        }
    }, [userData])

    return (
        <>
                <Container>
                    <Row className="justify-center checkout-page">
                        <Col xl={{ span: 9 }} md={12} className="px-[20px] lg:px-[10px] md:px-[15px]">
                            <div className=" border-mediumgray mt-[0px] sm:mt-[10px]">
                                <JobCard
                                setTab={props.setTab}
                                Jobs={jobs}
                                getJobs={getJobs}
                                grid="row-cols-1 row-cols-lg-1 gap-y-[15px] xs:gap-y-[15px]"
                                theme="icon-with-text-02"
                                // data={IconWithTextData_02}
                                animationDelay={0.2}
                                loading={false}
                            />
                              
                            </div>
                        </Col>
                    </Row>
                </Container>
        </>
    )
}

export default MyJobs
