import React, { useContext, useEffect, useRef, useState } from 'react'

// Libraries
import { Badge, Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { m } from 'framer-motion';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// Components
import Buttons from '../../Components/Button/Buttons'
import { Checkbox, Input, TextArea } from '../../Components/Form/Form'
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from "../../Components/Header/Header";
import { toast } from 'react-toastify';
import { listCities } from '../../Pages/api/cities';
import { AuthContext } from '../../contextApi/authContext';
import { changePassword, getUserDetails, resend_otp, updateUser } from '../../Pages/api/chefs';
import { resetForm } from '../../Functions/Utilities';

const ChefForgotPass = (props) => {
    const Router = useNavigate()
    const { userData } = useContext(AuthContext);

    const handleSubmit = async (values) => {
        try {
            values.id = userData.id
            const result = await changePassword(values)
            if (!result.error) {
                toast.success("Password changed successfully" || result.title)
                resetForm()
                Router('/my-account')
                props.setTab(2)
            } else {
                toast.error(result.title)
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong')
        }
    }

    const initialValues = {
        new_pass: '',
        confirm_pass: '',
        password: '',
    }
    const validationSchema = {
        new_pass: Yup.string().required("Please enter your password").min(8, 'Password must be 8 characters long'),
        confirm_pass: Yup.string().required("Please enter your password").oneOf([Yup.ref('new_pass'), null], 'Passwords must match').min(8, 'Password must be 8 characters long'),
        password: Yup.string().required("Please enter your password").min(8, 'Password must be 8 characters long'),
    }

    return (
        <>
            <Container>
                <Row className="justify-center checkout-page">
                    <Col xl={{ span: 8 }} md={12} className="lg:px-[10px] md:px-[15px]">
                        <div style={{boxShadow: '0 0 15px rgba(0, 0, 0, 0.08)'}}  className="bg-white p-16 border-mediumgray lg:mt-[35px] md:p-10">
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialValues}
                                validationSchema={Yup.object().shape(
                                    validationSchema
                                )}
                                onSubmit={async (values, actions) => {
                                    handleSubmit(values, actions);
                                    actions.resetForm()
                                }}
                            >
                                {({ isSubmitting, getFieldProps, status, handleChange, values, errors, touched, getFieldMeta, setValues }) => (

                                    <Form>
                                        <Input showErrorMsg={false} name="password" type="text" labelClass="mb-[20px]" label={<div className="mb-[15px]"> Password <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your password " />
                                        <Input showErrorMsg={false} name="new_pass" type="text" labelClass="mb-[20px]" label={<div className="mb-[15px]"> New Password <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your new password" />
                                        <Input showErrorMsg={false} name="confirm_pass" type="text" labelClass="mb-[20px]" label={<div className="mb-[15px]">Confirm Password <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Confirm password" />
                                        <Buttons ariaLabel="register" type="submit" className="btnImp  mt-4  btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]"  themeColor="#fff"  color="#fff" size="lg" title="Submit" />
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ChefForgotPass;
