import axios from "axios";
import Appconfig from "../../Constant/constant";

const createChef = async (values) => {
  try {
    const { data } = await axios.post(`${Appconfig.baseurl}/chefs`, values)
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const getChefs = async (values) => {
  try {
    const { name, location, position, cuisine, gender, sortBy, page, perPage, is_featured, filterBy } = values
    const { data } = await axios.get(`${Appconfig.baseurl}/chefs?name=${name || ''}&location=${location || ''}&filterBy=${filterBy}&position=${position || ''}&cuisine=${cuisine || ''}&gender=${gender || ''}&page=${page || 1}&perPage=${perPage || 12}&sortBy=${sortBy || 'ratings'}&is_featured=${is_featured || ''}`)
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const getDetails = async (id) => {
  try {
    const { data } = await axios.get(`${Appconfig.baseurl}/chefs/details?id=${id}`, {headers:{token:localStorage.getItem("token")}})
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const updateChef = async (values) => {
  try {
    const { data } = await axios.post(`${Appconfig.baseurl}/chefs/update`, values, {
      headers: { token: localStorage.getItem("token") }
    })
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const login = async (values) => {
  try {
    const { data } = await axios.post(`${Appconfig.baseurl}/chefs/login`, values)
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const forgotPasswordChef = async (values) => {
  try {
    const { data } = await axios.post(`${Appconfig.baseurl}/chefs/forgot_password`, values, {
      headers: { token: localStorage.getItem("token") }
    })
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const verifyOtpChef = async (values) => {
  try {
    const { data } = await axios.post(`${Appconfig.baseurl}/chefs/verify_otp`, values, {
      headers: { token: localStorage.getItem("temp_token") }
    })
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const resendOtpChef = async () => {
  try {
    const { data } = await axios.post(`${Appconfig.baseurl}/chefs/resend_otp`, null, {
      headers: { token: localStorage.getItem("temp_token") }
    })
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const changePassword = async (values) => {
  try {
    const { data } = await axios.post(`${Appconfig.baseurl}/chefs/change_password`, values, {
      headers: { token: localStorage.getItem("token") }
    })
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const updateDishImages = async (values) => {
  try {
    const { data } = await axios.post(`${Appconfig.baseurl}/chefs/update_dish_images`, values, {
      headers: { token: localStorage.getItem("token") }
    })
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const updateBankDetails = async (values) => {
  try {
    const { data } = await axios.post(`${Appconfig.baseurl}/chefs/update_bank_details`, values, {
      headers: { token: localStorage.getItem("token") }
    })
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const getInterestedJobs = async () => {
  try {
    const { data } = await axios.get(`${Appconfig.baseurl}/job_interest/chef_interests`, {
      headers: { token: localStorage.getItem("token") }
    })
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const createInterest = async (values) => {
  try {
    const { data } = await axios.post(`${Appconfig.baseurl}/job_interest`, values, {
      headers: { token: localStorage.getItem("token") }
    })
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

export {
  createChef,
  getChefs,
  getDetails,
  updateChef,
  login,
  forgotPasswordChef,
  verifyOtpChef,
  resendOtpChef,
  changePassword,
  updateDishImages,
  updateBankDetails,
  getInterestedJobs,
  createInterest,
}