import React, { memo } from 'react'

// Libraries
import { Accordion } from 'react-bootstrap'
import { m } from "framer-motion"
import { PropTypes } from "prop-types";

// Data
import {AccordionData} from './AccordionData'

// css
import "../../Assets/scss/components/_accordion.scss"
import Buttons from '../Button/Buttons';
import { useNavigate } from 'react-router-dom';

const Accordions = (props) => {
    const navigate = useNavigate()
   const handleOnClick=()=>{
navigate('/jobs')
    }
    return (
        <div className={`${props.theme} ${props.themeColor}${props.className ? ` ${props.className}` : ""}`}>
            <Accordion defaultActiveKey={0}>
                {
                    props.data.map((item, key) => {
                        return (
                            <m.div className={`accordion-item-wrapper`}
                                key={key}
                                {...{ ...props.animation, transition: { delay: key * props.animationDelay } }}
                            >
                                <Accordion.Item key={key} eventKey={key}>
                                    {item.position && item.position.length>0&&
                                        <Accordion.Header>
                                            {   <span className=" !min-w-[199px] sm:!min-w-[50px] md:!min-w-[100px] ">{key+1}</span>}
                                         
                                            { <span className="panel-title panel-time !min-w-[199px] sm:!min-w-[130px]">{props.label}</span>}
                                            { item.salary && <span className="panel-speaker !min-w-[199px] sm:!min-w-[80px]">{item.salary}</span>}
                                            { item.user_id && <span className="panel-speaker">{item.user_id.name}</span>}
                                        </Accordion.Header>
                                    }
                                    {item.address && <Accordion.Body className=' !ml-[199px] sm:!ml-[50px]'> {item.address}
                                    <span className='ml-2 '> <i onClick={handleOnClick} style={{cursor:'pointer'}}  className='fas fa-arrow-alt-circle-right pointer' />
                                   </span>
                                   
                                
                                    {/* {item && item.position&&item.position.length>0&&
                                          item.position.map((val,i)=>
                                          <span className="panel-title">{val}</span>)

                                          }  */}
                                          </Accordion.Body>}
                                </Accordion.Item>
                            </m.div>
                        )
                    })
                }
            </Accordion>
        </div>
    )
}

Accordions.defaultProps = {
    data: AccordionData,
    animationDelay: 0.2,
    theme: "accordion-style-01",
    themeColor: "light"
}

Accordion.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.exact({
            title: PropTypes.string,
            content: PropTypes.string
        })
    ),
    animation: PropTypes.object,
    animationDelay: PropTypes.number,
    theme: PropTypes.string,
    themeColor: PropTypes.string,
}

export default memo(Accordions)