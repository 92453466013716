import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// Libraries
import { BrowserRouter } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { LazyMotion, domMax } from "framer-motion";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// css
import "./Assets/css/icons.css"
import "./Assets/css/global.css"
import "./Assets/css/pages.css"
import "./index.scss"
import { AuthProvider } from "./contextApi/authContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LazyMotion features={domMax}>
    <ToastContainer />
    <ParallaxProvider>
      <BrowserRouter>
      <AuthProvider> 
        <App />
        </AuthProvider>
      </BrowserRouter>
    </ParallaxProvider>
  </LazyMotion>
);