import React, { memo } from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { PropTypes } from "prop-types";
import FooterData from './FooterData';
import FooterMenu, { Footer } from './Footer';
const FooterStyle01 = (props) => {
    return (
        <Footer theme={props.theme} className={`${props.className ? ` ${props.className}` : ""} bg-black font-serif`}>

            <div className="pt-8 border-t border-gray bg-[#1c84fd] px-2 pb-8 sm:pb-0 ">
                <Container className='px-16 sm:px-0' fluid={true}>
                    <Row className='justify-space-between'>
                        <Col className="sm:mb-[20px] px-4 flex">
                            <Link aria-label="homepage" to="/terms-of-service" className="sm:flex sm:justify-center px-2 !text-white hover:!text-white">
                                Terms
                            </Link>
                            <Link aria-label="homepage" to="/privacy-policy" className="sm:flex sm:justify-center px-2 !text-white hover:!text-white">
                                Privacy
                            </Link>
                        </Col>
                        <Col md={3} className="text-right sm:text-center">
                        </Col>

                    </Row>
                </Container>
            </div>
        </Footer>
    )
}

FooterStyle01.defaultProps = {
    data: FooterData,
    className: "bg-darkgray text-[#828282]",
    logo: "/assets/img/webp/logo-white.webp",
    theme: "light"
}

FooterStyle01.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.string,
}

export default memo(FooterStyle01)
