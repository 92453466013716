import React, { useState } from "react"
import Modal from 'react-bootstrap/Modal';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Buttons from "../Button/Buttons";

const CropImage = (prop) => {
    const { cropOpen, setCropOpen, image, setImage } = prop;
    const [cropper, setCropper] = useState("");

    const handleCropClose = () => {
        setCropper("");
        setCropOpen(false);
    }

    const getCropData = (e) => {
        e.preventDefault ()
        if (typeof cropper !== "undefined") {
            setImage({...image , preview: cropper.getCroppedCanvas().toDataURL()});
            handleCropClose()
        }
    };

    return (
        <Modal
            show={cropOpen}
            onHide={() => {
                setCropOpen(!cropOpen);
            }}
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0 font-medium text-black text-[22px]">Crop Image</h5>
                <button
                    type="button"
                    onClick={handleCropClose}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="mb-3">
                    <Cropper
                        style={{ height: 200, width: "100%" }}
                        zoomTo={0.5}
                        aspectRatio={1}
                        src={image && image.preview ? image.preview : ''}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={true}
                    />
                </div>
                <Buttons className="btnImp btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]"  themeColor="#fff" color="#fff" size="md" title="Submit" onClick={(e)=>getCropData(e)} type="button">
                    Crop Image
                </Buttons>
            </div>
        </Modal>
    )
}

export default CropImage