import React, { memo, useState } from 'react'

// Libraries
import { Row, Col, Tab, Tabs } from 'react-bootstrap'
import { PropTypes } from "prop-types";
import { m } from "framer-motion";

// Components
import Buttons from '../Button/Buttons'
import { fadeIn } from "../../Functions/GlobalAnimations";

// Data
import { TabData01 } from './TabData'
import EditUserForm from '../UserAccountTabContain/UserEditForm';
import ForgotPass from '../UserAccountTabContain/ForgotPassword';
import MyUserBooking from '../UserAccountTabContain/MyBooking';
import MyJobs from '../UserAccountTabContain/MyJobs';
import EditChefSignUp from '../ChefAccountTabContain/EditFormForChef';
import ChefForgotPass from '../ChefAccountTabContain/ForgotPassword';
import MyChefBooking from '../ChefAccountTabContain/MyChefBookings';
import ChefPictures from '../ChefAccountTabContain/MyDishPicture';
import LegalDocx from '../ChefAccountTabContain/LegalDocx';
import BankDetails from '../ChefAccountTabContain/BankDetails';

const ChefAccountTab = (props) => {

const [tab,setTab] =useState(1)
const handleSelect = (selectedTab) => {
    setTab(selectedTab);
  };
    return (
        <Tabs  activeKey={tab} onSelect={handleSelect} className=" justify-center tab-style-01 font-serif mb-[3.4rem] flex bg-transparent border-b-0 font-medium">
            <Tab className="tab-style-01 p-0 " eventKey={1} title={"Edit profile"}>

                <m.div className='items-center' {...{ ...fadeIn, transition: { duration: 0.9 }, viewport: { once: false } }}>
                    <Row className="justify-center items-center">
                        <Col lg={12} md={12} className="justify-center items-center md:mb-20 sm:mb-16">
                            <EditChefSignUp />
                        </Col>
                    </Row>
                </m.div>
            </Tab>
            <Tab className="tab-style-01" eventKey={2} title={"Change Password"}>
                <m.div className='items-center' {...{ ...fadeIn, transition: { duration: 0.9 }, viewport: { once: false } }}>
                    <Row className="items-center">
                        <Col lg={12} md={12} className="justify-center items-center md:mb-20 sm:mb-16">
                            <ChefForgotPass setTab={setTab} />
                        </Col>
                    </Row>
                </m.div>
            </Tab>
            {/* <Tab className="tab-style-01 p-0 " eventKey={3} title={"My Booking"}>

                <m.div className='row items-center' {...{ ...fadeIn, transition: { duration: 0.9 }, viewport: { once: false } }}>
                    <Row className="justify-center items-center">
                        <Col lg={12} md={12} className="md:mb-24 sm:mb-16 text-[18px] text-black">
                            <MyChefBooking />
                        </Col>
                    </Row>
                </m.div>
            </Tab> */}
            <Tab className="tab-style-01 p-0 " eventKey={3} title={"Pictures"}>

                <m.div className='items-center' {...{ ...fadeIn, transition: { duration: 0.9 }, viewport: { once: false } }}>
                    <Row className="justify-center items-center">
                        <Col lg={12} md={12} className="md:mb-24 sm:mb-16 text-[18px] text-black">
                            <ChefPictures setTab={setTab} />
                        </Col>
                    </Row>
                </m.div>
            </Tab>
            {/* <Tab className="tab-style-01 p-0 " eventKey={4} title={"Legal Docs"}>

                <m.div className='row items-center' {...{ ...fadeIn, transition: { duration: 0.9 }, viewport: { once: false } }}>
                    <Row className="justify-center items-center">
                        <Col lg={12} md={12} className="md:mb-24 sm:mb-16 text-[18px] text-black">
                            <LegalDocx />
                        </Col>
                    </Row>
                </m.div>
            </Tab> */}
            {/* <Tab className="tab-style-01 p-0 " eventKey={6} title={"Bank Details"}>

                <m.div className='row items-center' {...{ ...fadeIn, transition: { duration: 0.9 }, viewport: { once: false } }}>
                    <Row className="justify-center items-center">
                        <Col lg={12} md={12} className="md:mb-24 sm:mb-16 text-[18px] text-black">
                            <BankDetails/>
                        </Col>
                    </Row>
                </m.div>
            </Tab> */}
        </Tabs>
    )
}

ChefAccountTab.defaultProps = {
    data: TabData01
}

ChefAccountTab.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.exact({
            tabTitle: PropTypes.string,
            subtitle: PropTypes.string,
            title: PropTypes.string,
            img: PropTypes.string,
            content: PropTypes.string,
            buttonLink: PropTypes.string,
            buttonTitle: PropTypes.string,
            tabicons: PropTypes.string
        })
    ),
}


export default memo(ChefAccountTab)