import React, { useContext, useEffect, useRef, useState } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { m } from 'framer-motion';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// Components
import Buttons from '../../Components/Button/Buttons'
import { Checkbox, Input, TextArea } from '../../Components/Form/Form'
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from "../../Components/Header/Header";
import { toast } from 'react-toastify';
import { listCities } from '../../Pages/api/cities';
import { AuthContext } from '../../contextApi/authContext';
import { getUserDetails, resend_otp, updateUser } from '../../Pages/api/users';
import { resetForm } from '../../Functions/Utilities';

const EditUserForm = (props) => {
    const formikRef = useRef();
    const Router = useNavigate() 
    const { userData } = useContext(AuthContext)
    const [cityData, setCityData] = useState([])
    const [userDetails, setUserDetails] = useState({})
    const [intialval,setIntialVal] =useState({})
    const getUserdata = async () => {
        const result = await getUserDetails(userData.id) 
        if (!result.error) {
            setUserDetails(result.userData)
        } else {
            toast.error(result.title)
        }
    }
    const handleSubmit = async (values) => {
        values.id = userData.id
        const result = await updateUser(values)
        if (!result.error) {
            toast.success("User updated successfully" || result.title)
            resetForm()
            Router("/")
        } else {
            toast.error(result.title)
        }
    }
    const getCities = async () => {
        const result = await listCities()
        if (!result.error) {
            setCityData(result.citiesData || [])
        } else {
            toast.error(result.title)
        }
    }
    useEffect(() => {
        getCities()
    }, [])
    useEffect(() => {
        if (userData && userData.id) {
            getUserdata()
        }
    }, [userData])

    const InitailVal = {
        name: userDetails&&userDetails.name,
        email: '',
        phone_no: '',
        location: '',
    }
    const validationSchema = {
        name: Yup.string().required("Please enter your name"),
        email: Yup.string().email("Plese enter valid email").required("Please enter your email"),
        phone_no: Yup.string().min(10).max(10).required("Please enter your phone number"),
        location: Yup.string().required("Please select your location"),
    }
    useEffect(() => {
        if (userDetails && Object.keys(userDetails).length > 0) {
            setIntialVal({
                name: userDetails.name,
                email: userDetails.email,
                phone_no: userDetails.phone_no,
                location: userDetails.location,
            })
        }
    }, [userDetails])

    return (
        <>
                <Container>
                    <Row className="justify-center checkout-page">
                        <Col xl={{ span: 6 }} md={12} className="lg:px-[10px] md:px-[15px]">
                            <div style={{boxShadow: '0 0 15px rgba(0, 0, 0, 0.08)'}}  className="p-16 bg-white  border-mediumgray lg:mt-[35px] md:p-10">
                                <Formik
                                   enableReinitialize={true}
                                    innerRef={formikRef}
                                    initialValues={intialval}
                                    validationSchema={Yup.object().shape(
                                        validationSchema
                                    )}
                                    onSubmit={async (values, actions) => {
                                        handleSubmit(values, actions)
                                        }}
                                >
                                    {({ isSubmitting, getFieldProps, status, handleChange, values, errors, touched, getFieldMeta,setValues }) => (

                                        <Form>
                                            <Input showErrorMsg={false} name="name" type="text" labelClass="mb-[20px]" label={<div className="mb-[15px]">Name <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your name" />
                                            <Input showErrorMsg={false} name="email" type="email" labelClass="mb-[20px]" label={<div className="mb-[15px]">Email <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your email" />
                                            <Input showErrorMsg={false} name="phone_no" type="text" labelClass="mb-[20px]" label={<div className="mb-[15px]">Phone number <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your phone number" />
                                            <label className="mb-[15px]" htmlFor="contry">State <span className="text-red-500">*</span></label>
                                            <select name="location" id="contry-2" className={`${ getFieldMeta(values.location).error&& getFieldMeta(values.location).error.location&&getFieldMeta(values.location).touched? " dangerColorRed ":''}py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial] bg-white`}
                                                onChange={handleChange}
                                                value={values.location}
                                            >
                                                <option value=""> Current State</option>
                                                {
                                                    cityData.map(v => {
                                                        return <option value={v._id} key={v._id}> {v.name}</option>
                                                    })
                                                }
                                            </select>
                                            <Buttons ariaLabel="register" type="submit"  className="btnImp btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" themeColor="#fff" color="#fff" size="lg" title="Submit" />
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Col>
                    </Row>
                </Container>
        </>
    )
}

export default EditUserForm
