import React, { memo, useContext, useState } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap';
import { m } from 'framer-motion';
import { PropTypes } from "prop-types";
import { Link } from 'react-router-dom';

// components
import Buttons from '../Button/Buttons'

// Data
import { IconWithTextData_01 } from './IconWithTextData'

// css
import "../../Assets/scss/components/_iconwithtext.scss"
import { AuthContext } from '../../contextApi/authContext';
import Modal from '../CustomPopup';

const IconWithText = (props) => {
  const { userData } = useContext(AuthContext)
  const [open, setOpen] = useState(false)
  const [id, setId] = useState()
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleApply = (id) => {
    if (userData && userData.login_type == 'chef' && userData.id) {
      setOpen(true);
      setId(id)
    } else {
      props.toast.error("Please login as staff")
    }
  }
  const [img, setImg] = useState(['assets/img/Contact/gallops.png', 'assets/img/Contact/mcd.png', 'assets/img/Contact/starbucks.png', 'assets/img/Contact/yautcha.png'
    , 'assets/img/Contact/gallops.png', 'assets/img/Contact/mcd.png', 'assets/img/Contact/starbucks.png', 'assets/img/Contact/yautcha.png', 'assets/img/Contact/gallops.png', 'assets/img/Contact/starbucks.png'])
  return (
    <Row className={`${props.grid} md:justify-center`}>
      {props.loading ? <>
        <div className="row justify-content-center g-0 relative overflow-hidden rounder-[6px]" >
          <div
            class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
            <span
              class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Loading...
            </span>

          </div>
        </div>
      </> : !props.Jobs || props.Jobs.length < 1 ? <div className="row justify-content-center g-0 relative overflow-hidden rounder-[6px]" >
        <div className="mb-80 row justify-content-start text-[18px]"> No results found. Please adjust your filters and try again.</div></div> :
        props.Jobs && props.Jobs.map((jobData, i) => {
          return (
            <m.div key={i} className={`col${props.theme ? ` ${props.theme}` : ""}${props.className ? ` ${props.className}` : ""}`} {...{ ...props.animation, transition: { delay: i * props.animationDelay, ease: props.animationTransition, duration: props.animationDuration } }}>
              <div className="rounded-md w-100">
                {
                  userData && userData.login_type == 'chef' && userData.id ? (
                    <div style={{ alignItems: 'center' }} className='w-[298px] mr-[-23px] md:mr-[15px] mt-[4px] justify-start'>
                      <img className="inline-block items-center justify-center ml-[18px]" height='120' width='120'
                        src={jobData.job_profile_img}
                        // src={i>10?img[(Math.ceil(i/10))] :img[i]} 
                        alt="featurebox" />
                    </div>) : 
                    <div style={{ alignItems: 'start' }} className='w-[298px] mr-[3px] ml-[-47px] md:mr-[15px] flex justify-center'>
                    <img className="inline-block items-center justify-center ml-[18px]" height='120' width='120'
                      // src={i>10?img[(Math.ceil(i/10))] :img[i]}
                      src={jobData.job_profile_img}
                      alt="featurebox" />
                  </div>
                  }
                <div className='relative feature-box-content w-100 text-black'>
                  {/* {i<=3? <div className="backgroundGradient text-small text-white uppercase absolute font-medium text-sm top-[-35px] right-[-35px] py-[5px] px-[20px]"> Sponsored</div>:''} */}
                  {jobData.position && <span className="font-semibold title font-serif">
                    <span className='pinkText'>  {`${jobData && jobData.position && jobData.position.length > 1 ? (jobData.position[0].replace("_", " ").toUpperCase() + " and " + jobData.position[1].replace("_", " ").toUpperCase()) : jobData && jobData.position && jobData.position[0] ? jobData.position[0].replace("_", " ").toUpperCase() : ''}`}</span>
                    <span style={{ fontWeight: 'normal', fontWeight: '500' }}> at {jobData && jobData.location && jobData.location.name ? jobData.location.name : ''}</span>
                  </span>}
                  {
                    jobData && jobData.cuisine && jobData.cuisine.length > 0 &&
                    <p className="col-md-6 capitalize">
                      Cuisines:&nbsp;&nbsp;
                      {
                        jobData && jobData.cuisine && jobData.cuisine.length > 0 && jobData.cuisine.map(jobData => <span className='capitalize text-[1.05rem]'>{jobData}&nbsp;</span>)
                      }
                    </p>
                  }
                  <p> <span className='text-[gray]'> Experience: </span>  {jobData && jobData.experience ? jobData.experience : ''} years </p>
                  <Row className='row w-full'>
                    <Col xl={6} lg={7}><span className='text-[gray]'>Gender:</span>  {jobData && jobData.gender ? capitalizeFirstLetter(jobData.gender) : ''}</Col>
                    {/* <Col xl={6} lg={7}>Salary:  {jobData && jobData.salary ? jobData.salary : ''}</Col> */}
                  </Row>
                  <div>
                    <p> <span className='text-[gray]'> Salary: </span>  ₹ {jobData && jobData.salary ? jobData.salary : ''}</p>
                  </div>
                  {userData && userData.login_type == 'chef' && userData.id ?
                  <>
                   { props.interestedJobs && props.interestedJobs.includes(jobData._id) ? <Buttons disabled ariaLabel="button" className="btnImp mt-4 ml-[-1px] btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" size="md" themeColor="#232323" type={'submit'} color="#fff" title="Applied" /> :
                      <Buttons ariaLabel="button" className="btnImp mt-4 ml-[-1px] btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" size="md" themeColor="#fff" type={'submit'} color="#fff" title="Apply" onClick={() => { handleApply(jobData._id) }} />
                     } </>
                      :
                       <Buttons ariaLabel="button" href="/chef-login" className="btnImp mt-4 ml-[-1px] btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" size="md" themeColor="#fff" color="#fff" title="login to apply" />
                  }
                  {/* <Buttons ariaLabel="button" href="login" className="btnImp btn-fill mx-[10px] shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" size="md" themeColor="#fff" color="#fff" title="login" /> */}

                </div>
              </div>

              {open &&
                <Modal bgClass={'bg-white'} title={'Apply'} isOpen={() => { setOpen(true) }} onClose={() => { setOpen(false) }} >
                  <div className="text-left relative landscape:md:mt-[100px]" id="subscribe-popup">
                    <Container>
                      <Row className="justify-center">
                        <Col lg={11} md={10} className="relative">
                          <Row className="justify-center">
                            <Col className="px-2 py-2 bg-white overflow-hidden" xl='12' lg={12} sm={{ span: 11, order: 1 }} xs={{ span: 12, order: 2 }}>
                              Are you sure you want to Apply this job?
                            </Col>
                            <Col className="px-2 pb-2 bg-white overflow-hidden" xl='12' lg={12} sm={{ span: 11, order: 1 }} xs={{ span: 12, order: 2 }}>
                              <Buttons ariaLabel="button" onClick={() => { props.showInterest(id); setOpen(false) }} className="btnImp  mt-4  btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" size="sm" themeColor="#fff" type={'submit'} color="#fff" title="Yes" />
                              <Buttons ariaLabel="button" onClick={() => setOpen(false)} className="btnImp  mt-4  btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" size="sm" themeColor="#fff" type={'submit'} color="#fff" title="No" />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                    </Container>
                  </div>
                </Modal>
              }
            </m.div>
          )
        })
      }
    </Row>
  )
}

IconWithText.defaultProps = {
  data: IconWithTextData_01,
  animationDelay: 0.6,
  animationDuration: 0.8,
  animationTransition: "circOut",
  theme: "icon-with-text-01",
}

IconWithText.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.exact({
      icon: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      linkTitle: PropTypes.string,
      img: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  animation: PropTypes.object,
  animationDelay: PropTypes.number,
  animationTransition: PropTypes.string,
  theme: PropTypes.string,
  grid: PropTypes.string,
}


export default memo(IconWithText)