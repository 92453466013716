import React, { lazy } from "react";

// Libraries
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Keyboard, EffectFade } from "swiper";
import { Col, Container, Row, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AnimatePresence, m } from 'framer-motion'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link as ScrollTo } from "react-scroll"
import { Parallax } from "react-scroll-parallax";

// Functions
import { resetForm, sendEmail } from '../../Functions/Utilities';

import { Header, HeaderCart, HeaderNav, Menu, SearchBar, Topbar } from "../../Components/Header/Header";
import Buttons from '../../Components/Button/Buttons'
import { Input, TextArea } from '../../Components/Form/Form'
import MessageBox from '../../Components/MessageBox/MessageBox';
import Overlap from "../../Components/Overlap/Overlap";

import { TabData05 } from '../../Components/Tab/TabData'
import { ContactFormStyle02Schema } from '../../Components/Form/FormSchema';
import InteractiveBanners09 from '../../Components/InteractiveBanners/InteractiveBanners09';
// Animations
import { fadeIn, fadeInUp, zoomIn, rotateInLeft, fadeInLeft } from "../../Functions/GlobalAnimations"
import FooterStyle01 from "../../Components/Footers/FooterStyle01";
import { sendEnquiry } from "../api/users";
import { toast } from "react-toastify";
import Tab05 from "../../Components/Tab/Tab05";
import IconwithText4 from "../../Components/IconWithText/IconWithText4";
import { useContext } from "react";
import { AuthContext } from "../../contextApi/authContext";
import { ClientData02 } from "../../Components/Clients/ClientsData";
import Clients from '../../Components/Clients/Clients'
import IconWithText5 from "../../Components/IconWithText/IconWithText5";
import Appconfig from "../../Constant/constant";
import Modal from "../../Components/CustomPopup";
import CreateJobForm from "../../Components/CreateJobForm/CreateJobForm";

const IconWithText = lazy(() => import('../../Components/IconWithText/IconWithText'))

const handleSubmit = async (values) => {
    const result = await sendEnquiry(values)
    if (!result.error) {
        toast.success("Enquiry sent successfully")
        resetForm()
    } else {
        toast.error(result.title)
    }
}

const Home3 = (props) => {
    const swiperRef = React.useRef(null);
    const isDesktop = window.innerWidth > 768; // Animation stop at 768
    const { userData,jobOpen,setJobOpen } = useContext(AuthContext)
    const handleClick = () => {
        window.open(`${Appconfig.chef_url}`, '_blank', 'noopener,noreferrer');
    };
    const IconWithTextData_07 = [
        {
            img: '/assets/img/chef.jpg',
            title: "Chef",
            linkbtn: "chef",
            link: "/assets/Profession/chefs.png"
        },
        {
            img: "/assets/Profession/homecooks.png",
            linkbtn: "home_cook",
            title: "Home cook",
        },
        {
            img: '/assets/Profession/kitchenhelpers.png',
            linkbtn: "kitchen_helper",
            title: "Kitchen helper",
        },

        {
            img: "/assets/Profession/waiters.png",
            linkbtn: "waiter",
            title: "Waiter",
        },
        {
            img: "/assets/Profession/managers.png",
            linkbtn: "manager",
            title: "Manager",
        },
        {
            img: "/assets/Profession/bartenders.png",
            linkbtn: "bartender",
            title: "Bartender",
        },
        {
            img: "/assets/Profession/housekeeping.png",
            linkbtn: "housekeeping",
            title: "Housekeeping",
        },
        {
            img: "/assets/Profession/bakingspecialists.png",
            linkbtn: "baking_specialist",
            title: "Baking specialist",
        },
        {
            img: "/assets/Profession/receptionists.png",
            linkbtn: "receptionist",
            title: "Receptionist",
        }, 
        {
            img: "/assets/img/Images/delivery-staff.png",
            linkbtn: "delivery_staff",
            title: "Delivery staff",
        }, {
            img: "/assets/img/Images/cafe-staff.png",
            linkbtn: "cafe_staff",
            title: "Cafe staff",
        },
        {
            img: "/assets/img/Images/all-categories.png",
            linkbtn: "``",
            title: "All categories",
        },
    ]
    return (
        <div style={props.style} >
            <Header btn1={true} topSpace={{ desktop: true }} type="always-fixed border-b border-gray">
                <HeaderNav bg1={'#1c84fd'} bg="#1c84fd" fluid="fluid" theme="light" menu="light" className="px-[75px] py-[0px] md:py-[12px] sm:py-[15px] xs:py-[10px] md:pl-0 md:pr-[15px]" containerClass="px-0 sm:px-1 md:px-4 lg:px-8">
                    <Col xs={5} className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0 xs:!pr-0">
                        <Link aria-label="link for" className="flex items-center" to="/">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                {window.innerWidth > 960 ? <img style={{ minWidth: '250px', maxHeight: 'fit-content' }} className="default-logo default-logo min-w-[0px] md:min-w-[260px]" loading="lazy" src='/assets/img/logoblur.jpeg' alt='logo' /> : ''}
                                <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/Skype_Picture_2024_05_23T10_19_30_008Z-removebg-preview.png' alt='logo' />
                                {window.innerWidth <= 960 ? <img style={{ minWidth: '200px', maxHeight: 'fit-content', margin: '15px 0' }} className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/logoblur.jpeg' alt='logo' /> : ''}
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <Navbar.Toggle className="text-white order-last md:ml-[17px] md:px-[15px] xs:px-0 border-none">
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse className="col-auto col-lg-8 justify-end menu-order px-lg-0 md:!px-0 text-white">
                        <Menu {...props} />
                    </Navbar.Collapse>
                    {/* {localStorage.getItem('token') && <Col xs={5} className="xs:!pl-[0px] col-auto col-lg-2 text-end pe-0 font-size-0">
                        <HeaderCart className="xs:pl-[0px]" />
                    </Col>} */}
                </HeaderNav>
            </Header>
            <section className="font-serif bg-white px-16 lg:px-1 py-[50px] pb-[100px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
                <Container fluid={true} {...fadeIn} className="justify-center flex ">
                    <Col lg={12} md={12} sm={12} className="text-center mb-16 md:mb-16 sm:mb-8">
                        <div className="flex flex-row items-center justify-center text-center ">
                            <div className="bg-gradient-to-r from-[#5DE0E6] to-[#004AAD] bg-clip-text text-transparent font-serif font-semibold text-xmd px-[10px]">Click here for</div>
                        </div>
                        <div className="justify-center flex mb-2">
                            <img className="h-[15px]" style={{ transform: 'rotate(25deg)', margin: '8px 0px 10px 10px' }} src="/assets/img/logo (1.png"></img>
                        </div>
                        <div className="justify-center flex ">
                            <h2 onClick={handleClick} className="cursor-pointer bg-gradient-to-r from-[#5DE0E6] to-[#004AAD] py-0 font-bold w-[15%] sm:w-[239px] xs:w-[180px] md:w-[20%] lg:[18%] rounded-full text-white text-[20px] sm:text-[17px] font-serif font-semibold text-darkgray -tracking-[1px] sm:mb-[15px]">my kitchen jobs</h2>
                        </div>

                    </Col>
                </Container>
                <Container fluid={true}>
                    <IconWithText5
                        grid="row-cols-2 row-cols-lg-3 row-cols-sm-2 justify-start gap-y-24 md:gap-y-12 "
                        theme="icon-with-text-12"
                        data={IconWithTextData_07}
                        animation={fadeIn}
                        animationDelay={0.2}
                    />
                </Container>
            </section>
            <FooterStyle01 theme="dark" className="bg-[#262b35] text-slateblue" />
        </div>
    );
}

export default Home3