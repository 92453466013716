import React from "react";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    className="d-inline-block"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label className="multiselect-option-label">{props.label}</label>
            </components.Option>
        </div>
    );
};

export default function Multiselect(props) {
    const { selectedOptions, setSelectedOptions, options, className, maxLimit, isOpen, placeholder } = props;

    const handleChange = (selected) => {
        setSelectedOptions(selected)
    };

    return (
        <span
            className="d-inline-block w-100"
            data-toggle="popover"
            data-trigger="focus"
            data-content={placeholder ? placeholder : "Please selecet account(s)"}
        >
            <ReactSelect
                menuIsOpen={isOpen}
                options={options}
                className={className ? className : ""}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                    Option
                }}
                placeholder={placeholder ? placeholder : "Select..."}
                isOptionDisabled={(option) => {
                    let optionSelected = false
                    selectedOptions.map(v => {
                        if (v.value == option.value) {
                            optionSelected = true
                        }
                    })
                    if ((selectedOptions.length >= (maxLimit ? maxLimit : options.length)) && !optionSelected) {
                        return true
                    } else {
                        return false
                    }
                }}
                onChange={handleChange}
                allowSelectAll={false}
                value={selectedOptions}
            />
        </span>
    );
}