import axios from "axios";
import Appconfig from "../../Constant/constant";
const getUserListing = async () => {
    try {
        const { data } = await axios.get(`${Appconfig.baseurl}/users/`)
        return data;
    } catch (error) {
        return { title: error.message || "Something went wrong", error: true, data: error }
    }
}

const createUser = async (values) => {
    try {
        const { data } = await axios.post(`${Appconfig.baseurl}/users/`, values)
        return data;
    } catch (error) {
        return { title: error.message || "Something went wrong", error: true, data: error }
    }
}

const login = async (values) => {
    try {
        const { data } = await axios.post(`${Appconfig.baseurl}/users/login`, values)
        return data;
    } catch (error) {
        return { title: error.message || "Something went wrong", error: true, data: error }
    }
}

const sendEnquiry = async (values) => {
    try {
        const { data } = await axios.post(`${Appconfig.baseurl}/users/send_enquiry`, values)
        return data;
    } catch (error) {
        return { title: error.message || "Something went wrong", error: true, data: error }
    }
}

const updateUser = async (values) => {
    try {
        const { data } = await axios.post(`${Appconfig.baseurl}/users/update`, values,{
            headers: { token: localStorage.getItem("token") }})
        return data;
    } catch (error) {
        return { title: error.message || "Something went wrong", error: true, data: error }
    }
}

const changePassword = async (values) => {
    try {
        const { data } = await axios.post(`${Appconfig.baseurl}/users/change_password`, values,{
            headers: { token: localStorage.getItem("token") }})
        return data;
    } catch (error) {
        return { title: error.message || "Something went wrong", error: true, data: error }
    }
}

const getUserDetails = async (id) => {
    try {
        const { data } = await axios.get(`${Appconfig.baseurl}/users/details?id=${id}`, {
            headers: {
                token: localStorage.getItem('token')
            }
        })
        return data;
    } catch (error) {
        return { title: error.message || "Something went wrong", error: true, data: error }
    }
}

const forgot_password = async (values) => {
    try {
      const { data } = await axios.post(`${Appconfig.baseurl}/users/forgot_password`, values,{
        headers: { token: localStorage.getItem("token") }})
      return data;
    } catch (error) {
      return { title: error.message || "Something went wrong", error: true, data: error }
    }
  }
  
  const verify_otp = async (values) => {
    try {
      const { data } = await axios.post(`${Appconfig.baseurl}/users/verify_otp`, values,{
        headers: { token: localStorage.getItem("temp_token") }})
      return data;
    } catch (error) {
      return { title: error.message || "Something went wrong", error: true, data: error }
    }
  }

  const resend_otp = async () => {
    try {
      const { data } = await axios.post(`${Appconfig.baseurl}/users/resend_otp`, null,{
        headers: { token: localStorage.getItem("temp_token") }})
      return data;
    } catch (error) {
      return { title: error.message || "Something went wrong", error: true, data: error }
    }
  }
  const getInterestedJobsChefList = async () => {
    try {
      const { data } = await axios.get(`${Appconfig.baseurl}/job_interest/chef_interests_per_job`, {
        headers: { token: localStorage.getItem("token") }
      })
      return data;
    } catch (error) {
      return { title: error.message || "Something went wrong", error: true, data: error }
    }
  }
export {
    getUserListing,
    createUser,
    login,
    sendEnquiry,
    updateUser,
    getUserDetails,
    changePassword,
    forgot_password,
    verify_otp,
    resend_otp,
    getInterestedJobsChefList
}