import React, { memo, useEffect } from 'react'

// Libraries
import { Col, Nav, Row, Tab } from "react-bootstrap"
import { PropTypes } from "prop-types";

// Components
import Accordions from '../Accordion/Accordion'

// Data
import { TabData05 } from './TabData'
import { useState } from 'react';
import { getJobsForChef } from '../../Pages/api/jobs';
import { toast } from 'react-toastify';

const Tab05 = (props) => {
    const [jobs, setJobs] = useState([])
    const [positionData, setPositionData] = useState([])

    const [position, setPosition] = useState("")
    const [loading, setLoading] = useState(false)
    const [eventKey, setEventKey] = useState(1)
    const searchJobs = async (city, pageNo) => {

        setJobs([])
        setLoading(true)
        const result = await getJobsForChef({ position })
        console.log("fffffffffffffffffffslllllllllll",result)
        if (!result.error) {
            setJobs(result.jobsData.length>0 && result.jobsData)
            const FetchData = result.jobsData && result.jobsData[0] && result.jobsData.filter((val, i) => val.position.includes('desert_chef'))
            setPositionData(FetchData)
        } else {
            setJobs([])
            toast.error(result.title)
        }
        setLoading(false)
    }
    useEffect(() => {
        searchJobs()
    }, [])

    const fetchingDataByPosition = (Posit) => {
        console.log(Posit, "dddddddjjjjjjjjjjjjjjllllllllllll", jobs)
        const FetchData = jobs.filter((val, i) => val.position.includes(Posit))
        setPositionData(FetchData)
    }
    useEffect(() => {
        fetchingDataByPosition('desert_chef')
    }, [])

    const positions = [
        { value: 'chef', label: 'Chef' },
        { value: 'home_cook', label: 'Home Cook' },
        { value: 'kitchen_helper', label: 'Kitchen Helper' },
        { value: 'bartender', label: 'Bartender' },
        { value: 'waiter', label: 'Waiter' },
        { value: 'baking_specialist', label: 'Baking Specialist' },
        { value: 'housekeeping', label: 'Housekeeping' },
        { value: 'manager', label: 'Manager' },
        { value: 'receptionist', label: 'Receptionist' },
        { value:'delivery_staff', label:"Delivery staff"},
        { value:'cafe_staff', label:"Cafe staff"}
    ]
    return (
        <div>
            <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                <Row className="tab-style-05 justify-center">
                    <Col lg={12} className="text-center">
                        <Nav className="mb-[78px] md:mb-20 xs:mx-[15px] items-center justify-center font-medium uppercase">
                            {
                                positions.map((item, i) => {
                                    return (
                                        <Nav.Item key={i} className="">
                                            <Nav.Link className="text-center" onClick={() => fetchingDataByPosition(item.value)} eventKey={i}>
                                                {item.label}
                                            </Nav.Link>
                                        </Nav.Item>
                                    )
                                })
                            }
                        </Nav>
                    </Col>
                    <Col>
                        <Tab.Content>
                            <Tab.Pane eventKey={0} key={0}>
                                <Row className="justify-center">
                                    <Col lg={10} className='md:px-0'>
                                        <Accordions
                                            theme="accordion-style-05"
                                            className=""
                                            themeColor="light"
                                            data={positionData}
                                            key={6}
                                            label='DESERT CHEF'
                                        />
                                    </Col>
                                </Row>
                            </Tab.Pane>

                            <Tab.Pane eventKey={1} key={1}>
                                <Row className="justify-center">
                                    <Col lg={10} className='md:px-0'>
                                        <Accordions
                                            theme="accordion-style-05"
                                            className=""
                                            themeColor="light"
                                            data={positionData}
                                            key={1}
                                            label='HOME COOK'
                                        />
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey={2} key={2}>
                                <Row className="justify-center">
                                    <Col lg={10} className='md:px-0'>
                                        <Accordions
                                            theme="accordion-style-05"
                                            className=""
                                            themeColor="light"
                                            data={positionData}
                                            key={2}
                                            label='BARTENDER'
                                        />
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey={3} key={3}>
                                <Row className="justify-center">
                                    <Col lg={10} className='md:px-0'>
                                        <Accordions
                                            theme="accordion-style-05"
                                            className=""
                                            themeColor="light"
                                            data={positionData}
                                            key={3}
                                            label='WAITER'
                                        />
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey={4} key={4}>
                                <Row className="justify-center">
                                    <Col lg={10} className='md:px-0'>
                                        <Accordions
                                            theme="accordion-style-05"
                                            className=""
                                            themeColor="light"
                                            data={positionData}
                                            key={4}
                                            label='BARISTA'
                                        />
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey={5} key={5}>
                                <Row className="justify-center">
                                    <Col lg={10} className='md:px-0'>
                                        <Accordions
                                            theme="accordion-style-05"
                                            className=""
                                            themeColor="light"
                                            data={positionData}
                                            key={5}
                                            label='JUICE SPECIALIST'
                                        />
                                    </Col>
                                </Row>
                            </Tab.Pane>

                            {/* )}

                                })
                            } */}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
}

Tab05.defaultProps = {
    data: TabData05
}

// Tab05.propTypes = {
//     data: PropTypes.arrayOf(
//         PropTypes.exact({
//             tabTitle: PropTypes.string,
//             tabicons: PropTypes.string,
//             activities: PropTypes.arrayOf(
//                 PropTypes.exact({
//                     accordionid: PropTypes.string,
//                     accordionhref: PropTypes.string,
//                     time: PropTypes.string,
//                     title: PropTypes.string,
//                     content: PropTypes.string,
//                     author: PropTypes.string,
//                 })),
//         })
//     ),
// }

export default memo(Tab05)
