import axios from "axios";
import Appconfig from "../../Constant/constant";
const createJob = async (values) => {
  try {
    const { data } = await axios.post(`${Appconfig.baseurl}/jobs`, values, {
      headers: { token: localStorage.getItem("token") }
    })
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const editJob = async (values) => {
  try {
    const { data } = await axios.post(`${Appconfig.baseurl}/jobs/update`, values, {
      headers: { token: localStorage.getItem("token") }
    })
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const getJobs = async (values) => {
  try {
    const { experience, location, position, cuisine, gender, page, perPage } = values
    const { data } = await axios.get(`${Appconfig.baseurl}/jobs?experience=${experience || ''}&location=${location || ''}&position=${position || ''}&cuisine=${cuisine || ''}&gender=${gender || ''}&page=${page || 1}&perPage=${perPage || 12}`)
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}
const getJobsForChef = async (values) => {
  try {
    const { experience, location, position, cuisine, gender, page, perPage } = values
    const { data } = await axios.get(`${Appconfig.baseurl}/jobs/listjob`)
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const getUserJobs = async () => {
  try {
    const { data } = await axios.get(`${Appconfig.baseurl}/jobs/user_jobs`, {
      headers: { token: localStorage.getItem("token") }
    })
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const getDetails = async (values) => {
  try {
    const { id } = values
    const { data } = await axios.get(`${Appconfig.baseurl}/jobs/details?id=${id}`)
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

const changeStatus = async (values) => {
  try {
    const { data } = await axios.post(`${Appconfig.baseurl}/jobs/change_status`, values, {
      headers: { token: localStorage.getItem("token") }
    })
    return data;
  } catch (error) {
    return { title: error.message || "Something went wrong", error: true, data: error }
  }
}

export {
  createJob,
  editJob,
  getJobs,
  getDetails,
  getUserJobs,
  changeStatus,
  getJobsForChef
}