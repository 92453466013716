const HeaderData = [
    {
        title: 'User / Brand Login',
        link: '/login'
    },
    // {
    //     title: 'Chef / Staff Login',
    //     link: '/chef-login'
    // },
    // {
    //     title: 'About Us',
    //     link: '/about'
    // },
    // {
    //     title: 'Contact Us',
    //   link: '/contact'
    // },
   
    
]

export default HeaderData