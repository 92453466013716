import axios from "axios";
import Appconfig from "../../Constant/constant";
const addToWishlist = async (values) => {
    try {
        const { data } = await axios.post(`${Appconfig.baseurl}/wishlists/add`, values, {
            headers: { token: localStorage.getItem("token") }
        })
        return data;
    } catch (error) {
        return { title: error.message || "Something went wrong", error: true, data: error }
    }
}

const wistlists = async (id) => {
    try {
        const { data } = await axios.get(`${Appconfig.baseurl}/wishlists/list`, {
            headers: { token: localStorage.getItem("token") }
        })
        return data;
    } catch (error) {
        return { title: error.message || "Something went wrong", error: true, data: error }
    }
}

const staffWistlists = async (id) => {
    try {
        const { data } = await axios.get(`${Appconfig.baseurl}/wishlists/staff-list`, {
            headers: { token: localStorage.getItem("token") }
        })
        return data;
    } catch (error) {
        return { title: error.message || "Something went wrong", error: true, data: error }
    }
}

export {
    addToWishlist,
    wistlists,
    staffWistlists
}