import React, { useEffect, useRef, useState } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { m } from 'framer-motion';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Components
import { fadeIn } from '../../Functions/GlobalAnimations';
import { resetForm, sendEmail } from "../../Functions/Utilities";
import Buttons from '../../Components/Button/Buttons'
import { Checkbox, Input, TextArea } from '../../Components/Form/Form'
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from "../../Components/Header/Header";
import { Navbar } from 'react-bootstrap';
import FooterStyle01 from '../../Components/Footers/FooterStyle01';
import { toast } from 'react-toastify';
import { listCities } from '../api/cities';
import { createUser } from '../api/users';
import { type } from '@testing-library/user-event/dist/type';
import UserAccountTab from '../../Components/Tab/UserAccountTab';

const MyUserAccount = (props) => {
    const Router = useNavigate()
    const [cityData, setCityData] = useState([])

    const getCities = async () => {
        const result = await listCities()
        if (!result.error) {
            setCityData(result.citiesData || [])
        } else {
            toast.error(result.title)
        }
    }
    useEffect(() => {
        getCities()
    }, [])

    document.title = "My Account - my kitchen jobs";
    return (
        <>
            <Header btn1={true} topSpace={{ desktop: true }} type="always-fixed border-b border-gray">
                <HeaderNav bg1={'#1c84fd'} bg="#1c84fd" fluid="fluid" theme="light" menu="light" className="px-[75px] py-[0px] md:py-[12px] sm:py-[15px] xs:py-[10px] md:pl-0 md:pr-[15px]" containerClass="px-0 sm:px-1 md:px-4 lg:px-8">
                    <Col xs={5} className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0 xs:!pr-0">
                        <Link aria-label="link for" className="flex items-center" to="/">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                {window.innerWidth > 960 ? <img style={{ minWidth: '250px', maxHeight: 'fit-content'  }} className="default-logo default-logo min-w-[0px] md:min-w-[260px] my-4" loading="lazy" src='/assets/img/logoblur.jpeg' alt='logo' /> : ''}
                                <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/Skype_Picture_2024_05_23T10_19_30_008Z-removebg-preview.png' alt='logo' />
                                {window.innerWidth <= 960 ? <img style={{ minWidth: '200px', maxHeight: 'fit-content',margin:'15px 0' }} className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/logoblur.jpeg' alt='logo' /> : ''}
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <Navbar.Toggle className="text-white order-last md:ml-[17px] md:px-[15px] xs:px-0 border-none">
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse className="col-auto col-lg-8 justify-end menu-order px-lg-0 md:!px-0 text-white">
                        <Menu {...props} />
                    </Navbar.Collapse>
                    {/* {localStorage.getItem('token') && <Col xs={5} className="xs:!pl-[0px] col-auto col-lg-2 text-end pe-0 font-size-0">
                        <HeaderCart className="xs:pl-[0px]" />
                    </Col>} */}
                </HeaderNav>
            </Header>
            <m.section className="font-serif py-[130px] bg-lightgray overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px]"{...fadeIn}>
                <Container>
                    <Row className="justify-center">
                        <Col className="col-12 col-lg-11">
                            <UserAccountTab />
                        </Col>
                    </Row>
                </Container>
            </m.section>
            <FooterStyle01 theme="dark" className="text-slateblue bg-[#262b35]" />
        </>
    )
}

export default MyUserAccount
