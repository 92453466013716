import React ,{useState} from 'react';
import AWS from 'aws-sdk'
const S3_BUCKET ='findmychef';
const REGION ='ap-south-1';

var Buffer = require('buffer/').Buffer  // note: the trailing slash is important!

AWS.config.update({
    accessKeyId: 'AKIA4RMXNOYKS3T4J5NA',
    secretAccessKey: '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',
    region: REGION
})

const myBucket = new AWS.S3({params: { Bucket: S3_BUCKET}})

const s3BaseUrl = `https://s3.${REGION}.amazonaws.com/${S3_BUCKET}/`;
const chars = {'#': '%23', '$': '%24', '&': '%26', '+': '%2B', ':': '%3A', ';': '%3B', '=': '%3D', '?': '%3F','@': '%40','[': '%5B', ']': '%5D',
    '%': '%25','^': '%5E','{':'%7B','}':'%7D','|':'%7C','\\':'%5C','"':'%22','<':'%3C',',':'%2C','>':'%3E','`':'%60'}

const uploadImageToS3 = (filename, type, base64Str) => {
    console.log("sssssssssssssss",base64Str)
    const fileBuffer = Buffer.from(base64Str.replace(/.*base64,/, ""), 'base64')
    console.log("ddddddddddddddddddd",fileBuffer)
    var data = {
        Key: filename,
        Body: fileBuffer,
        ContentEncoding: 'base64',
        ContentType: type,
        ACL: 'public-read'
    };
    myBucket.putObject(data, function (err, data) {
        if (err) {
            console.log('File upload err',err);
        } else {
            console.log('File uploaded ', filename)
        }
    });
    return s3BaseUrl + filename.replace(/[@#$%^&+={[}\]|\\:;"<,>?`]/g, m => chars[m]);
}

export { 
    uploadImageToS3
}