import React, { memo } from 'react'

// Libraries
import { Row } from 'react-bootstrap';
import { m } from 'framer-motion';
import { PropTypes } from "prop-types";
import { Link } from 'react-router-dom';

// components
import Buttons from '../Button/Buttons'

// Data
import { IconWithTextData_01 } from './IconWithTextData'

// css
import "../../Assets/scss/components/_iconwithtext.scss"

const IconWithText4 = (props) => {
  return (
    <Row className={`${props.grid} md:justify-start `}>
      {
        props.data.map((item, i) => {
          return (
            <m.div key={i} className={`col${props.theme ? ` ${props.theme}` : ""}${props.className ? ` ${props.className}` : ""} mb-0 -mt-4 px-[10px]`} {...{ ...props.animation, transition: { delay: i * props.animationDelay, ease: props.animationTransition, duration: props.animationDuration } }}>
              <div className="rounded-md w-full">
                {
                  item.img ? (
                    <div style={{ boxShadow: '0 0 30px rgba(0,0,0,.08)', borderRadius: '10px', padding: '5px 5px 2px 5px', backgroundColor: 'white' }}>
                      <img style={{ borderRadius: '10px' }} className="inline-block items-center justify-center mb-[-2px]" src={item.img} alt="featurebox" >
                      </img>
                      {item.title && <h2 className="mt-[4px] mb-1 heading-4 text-[19px] sm:text-[16px] xs:text-[14px] text-black font-semibold font-normal mb-0 leading-[40px] sm:leading-[30px] text-center">{item.title} <span className="font-semibold">{item.titleTwo}</span></h2>
                      }
                    </div>
                  )
                    :
                    item.icon ? (props.theme === "icon-with-text-05" ? <Link aria-label="link for icon" to="#"><i className={item.icon}></i></Link> : <i className={item.icon}></i>
                    )
                      :
                      item.textIcon ? (<span className="text-basecolor inline-block icon-text">{item.textIcon}</span>)
                        :
                        <span className="text-basecolor inline-block icon-text">{`${i <= 9 ? "0" : ""}${i + 1}`}</span>
                }

                {/* <div className='feature-box-content'>
                  {item.title && <h2 className="mt-[3px] mb-1 heading-4 text-[19px] text-white font-semibold font-normal mb-0 leading-[40px] sm:leading-[30px] text-center">{item.title} <span className="font-semibold">{item.titleTwo}</span></h2>}
                </div> */}
                {/* {(item.linkTitle || item.link) && <Buttons ariaLabel="iconwithtext" className="font-medium font-serif uppercase btn-link after:h-[1px] md:text-md md:mb-[15px] after:bg-basecolor hover:text-basecolor" to={item.link} title={item.linkTitle} />} */}
              </div>
            </m.div>
          )
        })
      }
    </Row>
  )
}

IconWithText4.defaultProps = {
  data: IconWithTextData_01,
  animationDelay: 0.6,
  animationDuration: 0.8,
  animationTransition: "circOut",
  theme: "icon-with-text-01",
}

IconWithText4.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.exact({
      icon: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      linkTitle: PropTypes.string,
      img: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  animation: PropTypes.object,
  animationDelay: PropTypes.number,
  animationTransition: PropTypes.string,
  theme: PropTypes.string,
  grid: PropTypes.string,
}


export default memo(IconWithText4)