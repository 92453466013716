import React, { memo, useContext, useEffect, useState } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap';
import { m } from 'framer-motion';
import { PropTypes } from "prop-types";
import { Link, useNavigate } from 'react-router-dom';

// components
import Buttons from '../Button/Buttons'

// Data
import { IconWithTextData_01 } from './IconWithTextData'

// css
import "../../Assets/scss/components/_iconwithtext.scss"
import { AuthContext } from '../../contextApi/authContext';
import { changeStatus, getUserJobs } from '../../Pages/api/jobs';
import { toast } from 'react-toastify';
import CustomModal from '../CustomModal';
import CreateJobForm from '../CreateJobForm/CreateJobForm';
import EditUserForm from '../UserAccountTabContain/UserEditForm';
import EditJob from '../CreateJobForm/EditJobForm';
import { getInterestedJobsChefList } from '../../Pages/api/users';
import Modal from '../CustomPopup';
import Lists from '../Lists/Lists';
import { fadeIn } from '../../Functions/GlobalAnimations';

const JobCard = (props) => {
  const { userData } = useContext(AuthContext)
  const [jobs, setJobs] = useState([])
  const [open, setOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [statusOpen, setStatusOpen] = useState(false)
  const [selectedJob, setSelectedJob] = useState({})
  const [loading, setLoading] = useState(false)
  const [interestes, setInterested] = useState(false)
  const [interestesChef, setInterestedChef] = useState([])
  const [ChefList, setChefList] = useState([])

  const getJobs = async () => {
    const result = await getUserJobs(userData.id)
    if (!result.error) {
      setJobs(result.jobsData)
    } else {
      toast.error(result.title)
    }
  }
  const Router = useNavigate()
  const handleSubmit = async (data) => {
    setLoading(true)
    const values = {}
    values.id = data ? data._id : ''
    values.status = data && data.status == 'active' ? 'closed' : 'active'
    const result = await changeStatus(values)
    if (!result.error) {
      toast.success("Job status changed successfully")
    } else {
      toast.error(result.title)
    }
    setLoading(false)
    closeStatusPopup()
    Router('/my-account')
    props.setTab(3)
  }

  const closePopup = () => {
    setOpen(false)
    props.getJobs()
  }
  const OpenPopup = () => {
    setOpen(true)
  }
  const closeEditPopup = () => {
    setEditOpen(false)
    setSelectedJob({})
    props.getJobs()
  }
  const closeStatusPopup = () => {
    setStatusOpen(false)
    setSelectedJob({})
    props.getJobs()
  }
  const InterstedChefsPerJobs = async (jobData) => {

    const filteredChefs = await interestesChef.filter((chef) => chef.job_id === jobData._id)
    setChefList(filteredChefs)
    setInterested(true)
  }
  const getInterestChefInJobs = async () => {
    const result = await getInterestedJobsChefList(userData.id)
    if (!result.error) {
      setInterestedChef(result.interestData)
    } else {
      toast.error(result.title)
    }
  }
  useEffect(() => {
    getInterestChefInJobs()
  }, [])
  useEffect(() => {
    if (userData && userData.id) {
      props.getJobs()
    }
  }, [userData])
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const [img, setImg] = useState(['assets/img/Contact/starbucks.png', 'assets/img/Contact/mcd.png', 'assets/img/Contact/gallops.png', 'assets/img/Contact/yautcha.png'
    , 'assets/img/Contact/gallops.png', 'assets/img/Contact/mcd.png', 'assets/img/Contact/starbucks.png', 'assets/img/Contact/yautcha.png', 'assets/img/Contact/gallops.png', 'assets/img/Contact/starbucks.png'])

  return (
    <Row className={`${props.grid} md:justify-center`}>
      {props.loading ? <>
        <div className="row justify-content-center g-0 relative overflow-hidden rounder-[6px]" >
          <div
            class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
            <span
              class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Loading...
            </span>

          </div>
        </div>
      </> : !props.Jobs || props.Jobs.length < 1 ? <div className="row justify-content-center g-0 relative overflow-hidden rounder-[6px]" >
        <div className="d-flex justify-end mb-[-10px] mr-[20px]">
          <Buttons onClick={() => setOpen(true)} ariaLabel="button" className="btnImp mt-4 ml-1  btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" size="md" themeColor="#fff" type={'submit'} color="#fff" title="Create job" />
        </div>
        <div className="mb-80 row justify-content-center text-[20px] mt-2 sm:!mt-5">No data found</div>
      </div> :
        <>
          <div className="d-flex justify-end mb-[10px] mr-[20px]">
            <Buttons onClick={() => setOpen(true)} ariaLabel="button" className="btnImp mt-4 ml-1  btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" themeColor="#fff" size="md" type={'submit'} color="#fff" title="Create job" />
          </div>
          {props.Jobs && props.Jobs.map((jobData, i) => {
            return (
              <>
                <m.div key={i} className={`col${props.theme ? ` ${props.theme}` : ""}${props.className ? ` ${props.className}` : ""}`} {...{ ...props.animation, transition: { delay: i * props.animationDelay, ease: props.animationTransition, duration: props.animationDuration } }}>
                  <div className="rounded-md w-100">
                    <div style={{ alignItems: 'center' }} className='w-[298px] mr-[-23px] md:mr-[15px] mt-[4px] justify-center'>
                      <img className="inline-block items-center justify-center ml-[18px]" height='120' width='120'
                        src={jobData.job_profile_img}
                        alt="featurebox" />
                    </div>
                    <div className='relative feature-box-content w-100 text-black text-[15px]'>
                      {jobData.position && <span className="font-semibold title font-serif ">
                        <span className='pinkText'>  {`${jobData && jobData.position && jobData.position.length > 1 ? (jobData.position[0].replace("_", " ").toUpperCase() + " and " + jobData.position[1].replace("_", " ").toUpperCase()) : jobData && jobData.position && jobData.position[0] ? jobData.position[0].replace("_", " ").toUpperCase() : ''}`}</span>
                        <span style={{ fontWeight: 'normal', fontWeight: '500' }}> at {jobData && jobData.location && jobData.location.name ? jobData.location.name : ''}</span>
                      </span>}

                      <p> <span className='text-[gray]'> Experience: </span>  {jobData && jobData.experience ? jobData.experience : ''} years </p>
                      <Row className='row w-full'>
                        <Col xl={6} lg={7}><span className='text-[gray]'>Gender:</span>  {jobData && jobData.gender ? capitalizeFirstLetter(jobData.gender) : ''}</Col>
                        {/* <Col xl={6} lg={7}>Salary:  {jobData && jobData.salary ? jobData.salary : ''}</Col> */}
                      </Row>
                      <div>
                        <p> <span className='text-[gray]'> Salary: </span>  ₹ {jobData && jobData.salary ? jobData.salary : ''}</p>
                      </div>
                      <Buttons ariaLabel="button" onClick={() => { InterstedChefsPerJobs(jobData); }} className="btnImp  mt-4  btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" size="sm" themeColor="#fff" type={'submit'} color="#fff" title="Applicants" />
                      <Buttons ariaLabel="button" onClick={() => { setSelectedJob(jobData); setEditOpen(true) }} className="btnImp ml-1 mt-4 btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" size="sm" themeColor="#fff" type={'submit'} color="#fff" title="Edit" />
                      <Buttons ariaLabel="button" className="btnImp mt-4 ml-1  btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" size="sm" themeColor="#fff" type={'submit'} color="#fff" title={jobData.status == 'active' ? 'CLOSE' : 'Reopen'} onClick={() => { setSelectedJob(jobData); setStatusOpen(true) }} />

                    </div>
                  </div>
                </m.div>
                <div style={{ overflowY: 'scroll' }}>
                  {statusOpen &&
                    <Modal bgClass={'bg-white'} title={'Change Status'} isOpen={() => { setStatusOpen(true) }} onClose={() => { setStatusOpen(false) }} >
                      <div className="text-left relative landscape:md:mt-[100px]" id="subscribe-popup">
                        <Container>
                          <Row className="justify-center">
                            <Col lg={11} md={10} className="relative">
                              <Row className="justify-center">
                                <Col className="px-2 py-2 bg-white overflow-hidden" xl='12' lg={12} sm={{ span: 11, order: 1 }} xs={{ span: 12, order: 2 }}>
                                  Are you sure you want to change status of this job?
                                </Col>
                                <Col className="px-2 pb-2 bg-white overflow-hidden" xl='12' lg={12} sm={{ span: 11, order: 1 }} xs={{ span: 12, order: 2 }}>
                                  <Buttons ariaLabel="button" onClick={() => { handleSubmit(selectedJob) }} className="btnImp  mt-4  btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" size="sm" themeColor="#fff" type={'submit'} color="#fff" title="Yes" />
                                  <Buttons ariaLabel="button" onClick={() => setStatusOpen(false)} className="btnImp  mt-4  btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" size="sm" themeColor="#fff" type={'submit'} color="#fff" title="No" />
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                        </Container>
                      </div>
                    </Modal>
                  }
                  {editOpen &&
                    <Modal bgClass={'bg-white'} title={'Edit Job'} isOpen={() => { closeEditPopup(true) }} onClose={() => { closeEditPopup(false) }} >
                      <div className="text-left relative landscape:md:mt-[100px]" id="subscribe-popup">
                        <Container>
                          <Row className="justify-center">
                            <Col className="px-4 py-4 bg-white overflow-hidden" xl='12' lg={12} sm={{ span: 11, order: 1 }} xs={{ span: 12, order: 2 }}>
                              <EditJob setTab={props.setTab} show={editOpen} onHide={closeEditPopup} data={selectedJob} />
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Modal>
                  }
                  {interestes &&
                    <Modal bgClass={'bg-gray-100'} widthClass={''} title={'Applicants'} isOpen={() => { setInterested(true) }} onClose={() => { setInterested(false) }} >
                      <Container>
                        <Row className='justify-center'>
                          <Col xl={12} lg={12} md={12}>
                            {ChefList && ChefList.length > 0 ?
                              <Lists theme="list-style-05" data={ChefList} animation={fadeIn} />
                              :
                              <div className='w-[400px] mt-2'>
                                No chef interested in job.
                              </div>
                            }
                          </Col>
                        </Row>
                      </Container>
                    </Modal>
                  }
                </div>
              </>
            )
          })}
        </>
      }
      <div style={{ overflowY: 'scroll' }}>
        {open &&
          <Modal bgClass={'bg-white'} title={'Create Job'} isOpen={() => { setOpen(true) }} onClose={() => { setOpen(false) }} >
            <div className="text-left relative landscape:md:mt-[100px]" id="subscribe-popup">
              <Container>
                <Row className="justify-center">
                  <Col className="px-4 py-4 bg-white overflow-hidden" xl='12' lg={12} sm={{ span: 11, order: 1 }} xs={{ span: 12, order: 2 }}>
                    <CreateJobForm setTab={props.setTab} show={open} onHide={closePopup} OpenPopup={OpenPopup} />
                  </Col>
                </Row>
              </Container>
            </div>
          </Modal>
        }
      </div>
    </Row>
  )
}

JobCard.defaultProps = {
  data: IconWithTextData_01,
  animationDelay: 0.6,
  animationDuration: 0.8,
  animationTransition: "circOut",
  theme: "icon-with-text-01",
}

JobCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.exact({
      icon: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      linkTitle: PropTypes.string,
      img: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  animation: PropTypes.object,
  animationDelay: PropTypes.number,
  animationTransition: PropTypes.string,
  theme: PropTypes.string,
  grid: PropTypes.string,
}


export default memo(JobCard)