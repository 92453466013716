import React, { memo } from 'react'

// Libraries
import { Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { m } from "framer-motion"
import { PropTypes } from "prop-types";

//Data
// import { InteractiveBannersData09 } from './InteractiveBannersData'
const InteractiveBannersData09 = [
    {
        // img: "assets/img/Home/home-travel-agency-tour-intrest-img01.webp",
        img: 'assets/img/Home/OIP.jpeg',
        title: "Bangalore",
        // subtitle: "+990 Destinations",
        btnTitle: "More  ",
        btnLink: "chefs?city=Bangalore"
    },
    {
        // img: "assets/img/Home/home-travel-agency-tour-intrest-img02.webp",
        img: 'assets/img/Home/Chennai-Admire-The-Colonial-Architecture_26th-Mar.jpg',
        title: "Chennai",
        // subtitle: "+650 Destinations",
        btnTitle: "More  ",
        btnLink: "chefs?city=Chennai"
    },
    {
        // img: "assets/img/Home/home-travel-agency-tour-intrest-img03.webp",
        img: 'assets/img/Home/DELHI.jpg',
        title: "Delhi",
        // subtitle: "+250 Destinations",
        btnTitle: "More  ",
        btnLink: "chefs?city=Delhi"
    },
    {
        // img: "assets/img/Home/home-travel-agency-tour-intrest-img04.webp",
        img: 'assets/img/Home/mumbai.jpg',
        title: " Mumbai",
        // subtitle: "+350 Destinations",
        btnTitle: "More  ",
        btnLink: "/chefs?city=Mumbai"
    },
    {
        // img: "assets/img/Home/home-travel-agency-tour-intrest-img04.webp",
        img: '/assets/img/Home/Goacrop.jpg',
        title: " Goa",
        // subtitle: "+350 Destinations",
        btnTitle: "More  ",
        btnLink: "/chefs?city=Goa"
    },
    {
        // img: "assets/img/Home/home-travel-agency-tour-intrest-img04.webp",
        img: 'assets/img/Home/Hydrabadcrop.jpg',
        title: "Hyderabad",
        // subtitle: "+350 Destinations",
        btnTitle: "More ",
        btnLink: "/chefs?city=Hyderabad"
    },
    {
        // img: "assets/img/Home/home-travel-agency-tour-intrest-img04.webp",
        img: 'assets/img/Home/Jaipurcrop.jpeg',
        title: " Jaipur",
        // subtitle: "+350 Destinations",
        btnTitle: "More  ",
        btnLink: "/chefs?city=Jaipur"
    },
    {
        // img: "assets/img/Home/home-travel-agency-tour-intrest-img04.webp",
        img: 'assets/img/Home/Kolkatta2crop.jpg',
        title: "Kolkata",
        // subtitle: "+350 Destinations",
        btnTitle: "More  ",
        btnLink: "/chefs?city=Kolkata"
    }
]

const InteractiveBanners09 = (props) => {
    return (
        <Row className={`${props.grid}${props.className ? ` ${props.className}` : ""} mb-16`}>
            {
                props.data.map((item, i) => {
                    return (
                        <Link aria-label="link" to={item.btnLink} >
                            <m.div key={i} className="col interactive-banners-style-09 text-center" {...{ ...props.animation, transition: { delay: i * props.animationDelay, ease: props.animationTransition, duration: props.animationDuration } }}>
                                <div className="interactive-banners-content flex rounded-t-md flex-col items-center overflow-hidden py-12 bg-white shadow-[0_0_30px_rgba(0,0,0,0.08)] relative">
                                    <img width={120} height={120} src={item.img} className="scale mb-[20px] rounded-full w-[42%]" alt="trips" />
                                    <span className="font-serif text-darkgray block font-medium leading-[22px]">{item.title}</span>
                                    <span className="block">{item.subtitle}</span>
                                </div>
                            </m.div>
                        </Link>
                    )
                })
            }
        </Row>
    )
}

InteractiveBanners09.defaultProps = {
    data: InteractiveBannersData09,
    animationDuration: 0.4
}

InteractiveBanners09.propTypes = {
    className: PropTypes.string,
    grid: PropTypes.string,
    animationDelay: PropTypes.number,
    animation: PropTypes.object,
    data: PropTypes.arrayOf(
        PropTypes.exact({
            subtitle: PropTypes.string,
            title: PropTypes.string,
            img: PropTypes.string,
            btnTitle: PropTypes.string,
            imgLink: PropTypes.string,
            btnLink: PropTypes.string
        })
    )
}

export default memo(InteractiveBanners09)
