import React, { lazy, useEffect, useState, useContext } from 'react'
import { Col, Container, Row, Navbar } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { Parallax } from "react-scroll-parallax";
import { m } from 'framer-motion'
import { Input } from '../../Components/Form/Form'
// Components
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from "../../Components/Header/Header";
import FooterStyle01 from "../../Components/Footers/FooterStyle01";
import { fadeIn } from '../../Functions/GlobalAnimations';

import { toast } from "react-toastify"
// Data
import { getChefs } from '../api/chefs';
import { listCities } from '../api/cities';
import PaginationPro from '../../Components/Paginaton/pagination';
import IconWithText from '../../Components/IconWithText/IconWithText';
import { AuthContext } from '../../contextApi/authContext';
import { getJobs } from '../api/jobs';
import { createInterest, getInterestedJobs } from "../api/chefs";
import Buttons from '../../Components/Button/Buttons';
const Jobs = (props) => {
    const [jobs, setJobs] = useState([]);
    const [location, setLocation] = useState("")
    const [cuisine, setCuisine] = useState("")
    const [gender, setGender] = useState("")
    const [position, setPosition] = useState("")
    const [experience, setExperience] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(9)
    const [totalPages, setTotalPages] = useState(1)
    const [cityData, setCityData] = useState([])
    const [interestedJobs, setInterestedJobs] = useState([])
    const [loading, setLoading] = useState(false)
    const { userData } = useContext(AuthContext)

    useEffect(() => {
        setLocation(userData?.location)
        setPosition(userData?.position ? userData?.position[0] ? userData?.position[0] : '' : '')
        setGender(userData?.gender)
    }, [userData])

    let city = ''
    const searchJobs = async (city, pageNo) => {

        setJobs([])
        setLoading(true)
        const result = await getJobs({ experience, location: location || city || '', position, cuisine, gender, page: pageNo ? pageNo : page ? page : 1, perPage })
        if (!result.error) {
            setJobs(result.jobsData && result.jobsData[0] && result.jobsData[0].data ? result.jobsData[0].data : [])
            setTotalPages(result.jobsData && result.jobsData[0] && result.jobsData[0].metadata && result.jobsData[0].metadata[0] && result.jobsData[0].metadata[0].total ? Math.ceil(result.jobsData[0].metadata[0].total / perPage) : 1)
        } else {
            setJobs([])
            toast.error(result.title)
        }
        setLoading(false)
    }

    const getCities = async () => {
        const result = await listCities()
        if (!result.error) {
            setCityData(result.citiesData || [])
        } else {
            setCityData([])
            toast.error(result.title)
        }
    }

    useEffect(() => {
        getCities()
        // if (!city) {
        //     searchJobs()
        // }
    }, [])

    useEffect(() => {
        if (city && cityData && cityData.length > 0) {
            const urlCityData = cityData.filter(v => v.name && v.name.match(new RegExp(city, 'i')))
            const urlCityId = urlCityData && urlCityData[0] && urlCityData[0]._id ? urlCityData[0]._id : ''
            setLocation(urlCityId)
            // searchJobs(urlCityId)
        }
    }, [cityData, city])

    const getChefsJobs = async () => {
        const result = await getInterestedJobs()
        if (!result.error) {
            const jobs = result && result.interestData && result.interestData.length > 0 ? result.interestData.map(v => v.job_id) : []
            setInterestedJobs(jobs)
        } else {
            setInterestedJobs([])
            toast.error(result.title)
        }
    }

    useEffect(() => {
        if (userData && userData.login_type == 'chef' && userData.id) {
            getChefsJobs()
        }
    }, [userData])

    const showInterest = async (job_id) => {
        const result = await createInterest({ job_id })
        if (!result.error) {
            getChefsJobs()
        } else {
            setCityData([])
            toast.error(result.title)
        }
    }
    useEffect(() => {
        searchJobs()
    }, [position, location, gender, experience, cuisine])

    const handleOptionChange = (event) => {
        setGender(event.target.value);
    };
   const handleReset =()=>{
    setPosition('');
    setLocation('');
    setGender('')
    setExperience('');
    setCuisine('')
   }
    document.title = "Job - my kitchen jobs";

    return (
        <div style={props.style}>
            <Header btn1={true} topSpace={{ desktop: true }} type="always-fixed border-b border-gray">
                <HeaderNav bg1={'#1c84fd'} bg="#1c84fd" fluid="fluid" theme="light" menu="light" className="px-[75px] py-[0px] md:py-[12px] sm:py-[15px] xs:py-[10px] md:pl-0 md:pr-[15px]" containerClass="px-0 sm:px-1 md:px-4 lg:px-8">
                    <Col xs={5} className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0 xs:!pr-0">
                        <Link aria-label="link for" className="flex items-center" to="/">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                {window.innerWidth > 960 ? <img style={{ minWidth: '250px', maxHeight: 'fit-content' }} className="default-logo default-logo min-w-[0px] md:min-w-[260px]" loading="lazy" src='/assets/img/logoblur.jpeg' alt='logo' /> : ''}
                                <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/Skype_Picture_2024_05_23T10_19_30_008Z-removebg-preview.png' alt='logo' />
                                {window.innerWidth <= 960 ? <img style={{ minWidth: '200px', maxHeight: 'fit-content', margin: '15px 0' }} className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/logoblur.jpeg' alt='logo' /> : ''}
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <Navbar.Toggle className="text-white order-last md:ml-[17px] md:px-[15px] xs:px-0 border-none">
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse className="col-auto col-lg-8 justify-end menu-order px-lg-0 md:!px-0 text-white">
                        <Menu {...props} />
                    </Navbar.Collapse>
                    {/* {localStorage.getItem('token') && <Col xs={5} className="xs:!pl-[0px] col-auto col-lg-2 text-end pe-0 font-size-0">
                        <HeaderCart className="xs:pl-[0px]" />
                    </Col>} */}
                </HeaderNav>
            </Header>
            <m.div className=" bg-lightgray  h-[300px] sm:h-[350px] overflow-hidden" {...fadeIn}>
                <Container className="h-full relative">
                    <Row className="row items-stretch justify-center h-[300px] md:h-[375px] sm:h-[350px]">
                        <Col xl={6} lg={7} md={8} xs={12} className="text-center flex justify-center flex-col">
                            <h1 className="text-black text-xmd leading-[20px] font-serif mb-[15px] inline-block font-[400]">Browse jobs from various categories and locations</h1>
                            <h2 className="mb-[0px] font-serif font-medium -tracking-[1px] xs:mb-[0px] tracking-[-3px] pinkText">Jobs</h2>
                        </Col>
                    </Row>
                </Container>
            </m.div>
            <section className="bg-lightgray  shopping-right-left-sidebar pt-0 py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]">
                <Container>
                    <Row>
                        <m.aside className="col col-lg-3 col-md-4 shopping-sidebar inline-block order-md-1 order-1 mb-[50px]" {...fadeIn}>
                            {/* <div className=" relative ">
                                <input type='number' name='experience' placeholder='Experience'
                                    onChange={(e) => setExperience(e.target.value)}
                                    className='py-3 pr-10 pl-4 text-sm border border-gray-300 w-full mb-6 leading-none' />
                                <span className="text-gray absolute right-4 top-1/2 transform -translate-y-[90%] text-sm text-gray-500 pointer-events-none">years</span>
                            </div> */}
                            <div className=" relative ">  {/*checkout-page */}
                                <select value={location} name="contry" id="contry-2" onChange={(e) => setLocation(e.target.value)}
                                    className="py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial] appearance-none bg-white">
                                    <option value={''}>All States</option>
                                    {cityData.map(v => {
                                        return <option value={v._id}> {v.name}</option>
                                    })}

                                </select>
                                <div class="absolute mb-[25px] inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                    <svg class="w-10 h-10 fill-current" viewBox="0 0 20 20">
                                        <path d="M10 12l-4-4h8l-4 4z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className=" relative">
                                <select id='selectOption' value={position} name="contry" onChange={(e) => { setCuisine(''); setPosition(e.target.value); }}
                                    className="py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial] appearance-none bg-white">
                                    <option value="">All Profession</option>
                                    <option value='chef'>Chef</option>
                                    <option value='home_cook'>Home Cook</option>
                                    <option value='kitchen_helper'>Kitchen Helper</option>
                                    <option value='bartender'>Bartender</option>
                                    <option value='waiter'>Waiter</option>
                                    <option value='baking_specialist'>Baking Specialist</option>
                                    <option value='housekeeping'>Housekeeping</option>
                                    <option value="receptionist">Receptionist</option>
                                    <option value='manager'>Manager</option>
                                    <option value='delivery_staff'>Delivery staff</option>
                                    <option value='cafe_staff'>Cafe staff</option>
                                </select>
                                <div class="absolute inset-y-5 mb-[25px] right-0 flex items-center px-2 pointer-events-none">
                                    <svg class="w-10 h-10 fill-current" viewBox="0 0 20 20">
                                        <path d="M10 12l-4-4h8l-4 4z"></path>
                                    </svg>
                                </div>
                            </div>
                            {/* <div className=" relative">
                                {
                                    (position?.includes('chef') || position?.includes('cuisine_specialist')) &&
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form_group mb-30">
                                            <select
                                                className="py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial] appearance-none"
                                                name="cuisines" value={cuisine} onChange={(e) => setCuisine(e.target.value)}>
                                                <option value=""> Cuisines</option>
                                                <option value='indian'> Indian</option>
                                                <option value='japanese'> Japanese</option>
                                                <option value='mexican'> Mexican</option>
                                                <option value='american'> American</option>
                                                <option value='greek'> Greek</option>
                                            </select>
                                            <div class="absolute inset-y-5 mb-[25px] right-0 flex items-center px-2 pointer-events-none">
                                                <svg class="w-10 h-10 fill-current" viewBox="0 0 20 20">
                                                    <path d="M10 12l-4-4h8l-4 4z"></path>
                                                </svg>
                                            </div>

                                        </div>
                                    </div>
                                }
                            </div> */}
                            <div className="border-b border-mediumgray pb-4 mb-4 relative">
                                <span className="shop-title relative font-serif font-medium text-darkgray block mb-[15px]">Gender</span>
                                <div className="list-style filter-category" onChange={(e) => setGender(e.target.value)}>
                                    <label><input value={'female'} className='mr-2' type='radio' aria-label="product-category" rel="noreferrer" checked={gender === 'female'}
                                        onClick={handleOptionChange}  ></input>Female</label>
                                    <label className='ml-6 pl-2'><input className='mr-2' value={'male'} type='radio' aria-label="product-category" rel="noreferrer" checked={gender === 'male'}
                                        onClick={handleOptionChange} ></input>Male</label>
                                    <label className='ml-6 pl-2'><input className='mr-2' value={''} type='radio' aria-label="product-category" rel="noreferrer" checked={gender === ''}
                                        onClick={handleOptionChange} ></input>All</label>
                                </div>
                            </div>
                            <div className="font-serif border-b border-mediumgray pb-4 mb-4 relative">
                                <Buttons onClick={handleReset} type="submit" className="btnImp btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" themeColor="#fff" color="#fff" size="md" title="view all" />
                            </div>
                        </m.aside>
                        <Col lg={9} md={8} className="pl-[55px] md:pl-[15px] sm:mb-[30px] order-md-2 order-1 sm:px-3">
                            <IconWithText
                                interestedJobs={interestedJobs} showInterest={showInterest}
                                Jobs={jobs}
                                grid="row-cols-1 row-cols-lg-1 gap-y-[30px] xs:gap-y-[15px] "
                                theme="icon-with-text-02"
                                animation={fadeIn}
                                animationDelay={0.2}
                                loading={loading}
                                toast={toast}
                            />
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-end mt-20">
                        <PaginationPro page={page} setPage={setPage} totalPages={totalPages}
                            getData={searchJobs}
                        />
                    </div>
                </Container>
            </section>

            <FooterStyle01 theme="dark" className="text-slateblue bg-[#262b35]" />
        </div>
    )
}

export default Jobs
