const Appconfig = {
  // baseurl: "https://fmc-api.infiny.dev",
  // baseurl: "http://localhost:4001",
  // baseurl: "http://192.168.1.30:4001",
  // baseurl:"http://13.233.30.156:4001" ,
  // baseurl:"https://api.findmychef.co.in",
  baseurl: "https://api.mykitchenjobs.com",
  // baseurl:"https://api.findmychef.services",
  // chef_url:"https://d3ofvgeiztg4g4.cloudfront.net",
  // chef_url:"https://www.mykitchenjobs.com"
  chef_url: "/jobs",
  // chef_url:"http://192.168.1.30:3000",
};

export default Appconfig;
