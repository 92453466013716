import React, { useEffect, useRef, useState } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { m } from 'framer-motion';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Components
import { fadeIn } from '../../Functions/GlobalAnimations';
import { resetForm, sendEmail } from "../../Functions/Utilities";
import Buttons from '../../Components/Button/Buttons'
import { Checkbox, Input, TextArea } from '../../Components/Form/Form'
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from "../../Components/Header/Header";
import { Navbar } from 'react-bootstrap';
import FooterStyle01 from '../../Components/Footers/FooterStyle01';
import { toast } from 'react-toastify';
import { listCities } from '../../Pages/api/cities';
import { createUser } from '../../Pages/api/users';
import Multiselect from '../../Components/Multiselect';
import { createJob, editJob } from '../../Pages/api/jobs';
const EditJob = (props) => {
    const Router = useNavigate()
    const { show, onHide, data } = props
    const [cityData, setCityData] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedPositions, setSelectedPositions] = useState([])
    const [selecetedCuisines, setSelectedCuisines] = useState([])
    const [initialVal, setInitialVal] = useState({})
    const handleSubmit = async (values) => {
        setLoading(true)
        if (!selectedPositions || selectedPositions.length < 1 || selectedPositions.length > 2) {
            return toast.error("Please select minimum 1 and maximum 2 positions")
        }
        const position = selectedPositions.map(v => v.value)

        if ((position.includes('chef') || position.includes('cuisine_specialist')) && (!selecetedCuisines || selecetedCuisines.length < 1)) {
            return toast.error("Please select minimum 1 cuisine")
        }
        const cuisine = selecetedCuisines.map(v => v.value)

        values.id = data._id
        values.position = position
        values.cuisine = cuisine
        const result = await editJob(values)
        if (!result.error) {
            toast.success("Job edited successfully")
            resetForm()
            setSelectedPositions([])
            setSelectedCuisines([])
            Router('/my-account')
            props.setTab(3)
        } else {
            toast.error(result.title)
        }
        setLoading(false)
        onHide()
    }

    const getCities = async () => {
        const result = await listCities()
        if (!result.error) {
            setCityData(result.citiesData || [])
        } else {
            toast.error(result.title)
        }
    }

    useEffect(() => {
        getCities()
    }, [])

    const InitailVal = {
        gender: '',
        location: '',
        address: '',
        salary: '',
        experience: '',
    }
    const validationSchema = {
        gender: Yup.string().required("Please enter your gender"),
        location: Yup.string().required("Please enter your location"),
        address: Yup.string().required("Please enter your address"),
        salary: Yup.number().required("Please enter your visit rate"),
        experience: Yup.number().required("Please enter your experience"),
    }
    const positions = [
        { value: 'chef', label: 'Chef' },
        { value: 'home_cook', label: 'Home Cook' },
        { value: 'kitchen_helper', label: 'Kitchen Helper' },
        { value: 'bartender', label: 'Bartender' },
        { value: 'waiter', label: 'Waiter' },
        { value: 'baking_specialist', label: 'Baking Specialist' },
        { value: 'housekeeping', label: 'Housekeeping' },
        { value: 'manager', label: 'Manager' },
        { value: 'receptionist', label: 'Receptionist' },
        { value:'delivery_staff', label:"Delivery staff"},
        { value:'cafe_staff', label:"Cafe staff"}
    ]

    const cuisines = [
        { value: 'indian', label: 'Indian' },
        { value: 'japanese', label: 'Japanese' },
        { value: 'mexican', label: 'Mexican' },
        { value: 'american', label: 'American' },
        { value: 'greek', label: 'Greek' }
    ]
    useEffect(() => {
        setInitialVal({
            gender: data && data.gender ? data.gender : '',
            location: data && data.location ? data.location._id : '',
            address: data && data.address ? data.address : '',
            salary: data && data.salary ? data.salary : '',
            experience: data && data.experience ? data.experience : '',
        })
        const position = data && data.position && positions.filter(v => data.position.includes(v.value))
        const cuisine = data && data.cuisine && cuisines.filter(v => data.cuisine.includes(v.value))
        setSelectedPositions(position || [])
        setSelectedCuisines(cuisine || [])
    }, [data])
    return (
        <>
            <Container>
                <Row className="justify-center checkout-page ">
                    <Col xl={{ span: 12 }} md={12} >
                        {/* <h6 className="font-serif font-medium text-darkgray">Edit Job</h6> */}
                        <div className="lg:mt-[35px] ">
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialVal}
                                validationSchema={Yup.object().shape(
                                    validationSchema
                                )}
                                onSubmit={async (values, actions) => {
                                    handleSubmit(values, actions)
                                }}
                            >
                                {({ isSubmitting, getFieldProps, status, handleChange, values, errors, touched, getFieldMeta }) => (

                                    <Form>
                                        <Row className="justify-center checkout-page ">
                                            <Col md={12} >
                                                <div>
                                                    <Multiselect value={selectedPositions} placeholder="Positions" selectedOptions={selectedPositions}  className="cuisine-dropdown mb-[25px]" setSelectedOptions={setSelectedPositions} options={positions}  maxLimit={1} />
                                                </div>
                                            </Col>
                                            <Col md={12} >
                                                {
                                                    selectedPositions && selectedPositions[0] && (selectedPositions[0].value == 'chef' || selectedPositions[0].value == 'cuisine_specialist' || (selectedPositions[1] && (selectedPositions[1].value == 'chef' || selectedPositions[1].value == 'cuisine_specialist'))) &&

                                                    <Multiselect
                                                        value={cuisines}
                                                        selectedOptions={selecetedCuisines}
                                                        setSelectedOptions={setSelectedCuisines}
                                                        options={cuisines}
                                                        placeholder="Cuisines"
                                                        className="cuisine-dropdown mb-[25px]"
                                                    />

                                                }
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col xl={{ span: 6 }} md={12} >
                                                <select name="gender" id="contry-2" className="py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial] bg-white"
                                                    onChange={handleChange}
                                                    value={values.gender}
                                                >
                                                    <option value=""> Gender </option>
                                                    <option value="male"> Male</option>
                                                    <option value="female"> Female</option>
                                                </select>
                                            </Col>
                                            <Col xl={{ span: 6 }} md={12} >
                                                <select name="location" id="contry-2" className="py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial] bg-white"
                                                    onChange={handleChange}
                                                    value={values.location}
                                                >
                                                    <option value=""> States</option>
                                                    {
                                                        cityData.map(v => {
                                                            return <option value={v._id} key={v._id}> {v.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xl={{ span: 6 }} md={12} >
                                                <Input showErrorMsg={false} name="experience" type="number" labelClass="mb-[20px]" className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Experience in year" />
                                            </Col>
                                            <Col xl={{ span: 6 }} md={12} >
                                                <Input showErrorMsg={false} name="salary" type="text" labelClass="mb-[20px]" className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Salary" />
                                            </Col>
                                        </Row>
                                        <TextArea showErrorMsg={false} name="address" labelClass="mb-[20px]" className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Address" />

                                        <Buttons ariaLabel="register" type="submit" className="btnImp  mt-2  btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" themeColor="#fff" color='#000' size="lg" title="Confirm" />
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default EditJob
