import axios from "axios";
import Appconfig from "../../Constant/constant";

const listCities = async () => {
    try {
        const {data} = await axios.get(`${Appconfig.baseurl}/cities/`,)
        return data;
    } catch (error) {
        return {title: error.message || "Something went wrong", error: true, data: error}
    }

}

export {
    listCities
}