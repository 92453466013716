import React, { useRef } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { m } from 'framer-motion';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Components
import { fadeIn } from '../../Functions/GlobalAnimations';
import { resetForm, sendEmail } from "../../Functions/Utilities";
import Buttons from '../../Components/Button/Buttons'
import { Checkbox, Input, TextArea } from '../../Components/Form/Form'
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from "../../Components/Header/Header";
import { Navbar } from 'react-bootstrap';
import FooterStyle01 from '../../Components/Footers/FooterStyle01';
import { toast } from 'react-toastify';
import { login } from '../api/users';

const UserLogin = (props) => {
    let Router = useNavigate()
    const handleSubmit = async (values) => {
        const result = await login(values)
        if (!result.error) {
            localStorage.setItem('token', result.token)
            Router('/')
            toast.success("logged in successfully" || result.title)
        } else {
            toast.error(result.title)
        }
    }
    document.title = "Login - my kitchen jobs";

    return (
        <>
             <Header btn1={true} topSpace={{ desktop: true }} type="always-fixed border-b border-gray">
                <HeaderNav bg1={'#1c84fd'} bg="#1c84fd" fluid="fluid" theme="light" menu="light" className="px-[75px] py-[0px] md:py-[12px] sm:py-[15px] xs:py-[10px] md:pl-0 md:pr-[15px]" containerClass="px-0 sm:px-1 md:px-4 lg:px-8">
                    <Col xs={5} className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0 xs:!pr-0">
                        <Link aria-label="link for" className="flex items-center" to="/">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                {window.innerWidth > 960 ? <img style={{ minWidth: '250px', maxHeight: 'fit-content' }} className="default-logo default-logo min-w-[0px] md:min-w-[260px]" loading="lazy" src='/assets/img/logoblur.jpeg' alt='logo' /> : ''}
                                <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/Skype_Picture_2024_05_23T10_19_30_008Z-removebg-preview.png' alt='logo' />
                                {window.innerWidth <= 960 ? <img style={{ minWidth: '200px', maxHeight: 'fit-content', margin:'15px 0' }} className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/logoblur.jpeg' alt='logo' /> : ''}
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <Navbar.Toggle className="text-white order-last md:ml-[17px] md:px-[15px] xs:px-0 border-none">
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse className="col-auto col-lg-8 justify-end menu-order px-lg-0 md:!px-0 text-white">
                        <Menu {...props} />
                    </Navbar.Collapse>
                    {/* {localStorage.getItem('token') && <Col xs={5} className="xs:!pl-[0px] col-auto col-lg-2 text-end pe-0 font-size-0">
                        <HeaderCart className="xs:pl-[0px]" />
                    </Col>} */}
                </HeaderNav>
            </Header>
            <m.section className="font-serif py-[130px] bg-lightgray overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px]"{...fadeIn}>
                <Container>
                    <Row className="justify-center">
                        <Col xl={5} md={6} className="lg:px-[30px] md:px-[15px] sm:mb-[40px]">
                            <h6 className="font-serif font-medium text-darkgray">User Login</h6>
                            <div style={{ boxShadow: '0 0 15px rgba(0, 0, 0, 0.08)' }} className="bg-white p-16 lg:mt-[35px] md:p-10">
                                <Formik
                                    initialValues={{ email: '', password: '' }}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string().email("Invalid email.").required("Field is required."),
                                        password: Yup.string().required("Field is required.")
                                    })}
                                    onSubmit={async (values, actions) => {
                                        handleSubmit(values)
                                    }}
                                >
                                    {({ isSubmitting, status }) => (
                                        <Form>
                                            <Input showErrorMsg={false} name="email" type="email" labelClass="mb-[20px]" label={<div className="mb-[15px]">Email address <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] text-md leading-[initial] w-full border-[1px] border-solid border-[#dfdfdf]" placeholder="Enter your email" />
                                            <Input showErrorMsg={false} name="password" type="password" labelClass="mb-[20px]" label={<div className="mb-[15px]">Password <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] text-md leading-[initial] w-full border-[1px] border-solid border-[#dfdfdf]" placeholder="Enter your password" />
                                           <div className='flex'>
                                            <Buttons ariaLabel="login" type="submit" className="btnImp btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" themeColor="#fff" color="#fff" size="lg" title="Login" />
                                            <Link className={`nav-link ml-2 `} to={'/user-signup'}>{
                                                    <button class=" btnImp text-black rounded-[50px] bg-transparent px-5 py-3 flex items-center space-x-2 transition duration-300">
                                                        <span className="text-white font-medium text-[13px]">SIGN UP</span>
                                                        <span class="text-white text-[13px] transform transition-transform duration-300 group-hover:translate-x-1 fas fa-arrow-right"></span>
                                                    </button>
                                                }</Link>
                                                 </div>
                                            <div className='flex justify-content-between'>
                                                {/* <div className='mt-4'>
                                                    <p className='text-black'>
                                                        <p className='inline'> New user? </p> <Buttons ariaLabel="reset-password" href="/user-signup" className="text-right text-[12px] btn-link font-medium font-serif uppercase btn after:h-[2px] md:text-md !p-[0px 0 2px]" size="md" color="#fff" title="Sign up" />
                                                    </p>
                                                </div> */}
                                                <div></div>
                                                <div className="text-right mt-4">
                                                    <Buttons ariaLabel="reset-password" href="/forgot-password" className="text-right text-[12px] !p-[0px 0 2px] btn-link font-medium font-serif uppercase btn after:h-[2px] md:text-md" size="md" color="#000" title="Lost your password?" />
                                                </div>
                                            </div>


                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </m.section>
            <FooterStyle01 theme="dark" className="text-slateblue bg-[#262b35]" />
        </>
    )
}

export default UserLogin
