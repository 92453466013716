import React, { useRef, useEffect, useState, useContext } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { m } from 'framer-motion';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from 'yup';
// Components
import { fadeIn } from '../../Functions/GlobalAnimations';
import { resetForm, sendEmail } from "../../Functions/Utilities";
import Buttons from '../../Components/Button/Buttons'
import { Checkbox, Input, TextArea } from '../../Components/Form/Form'
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from "../../Components/Header/Header";
import { Navbar } from 'react-bootstrap';
import FooterStyle01 from '../../Components/Footers/FooterStyle01';
import { toast } from 'react-toastify';
import { createChef, updateChef } from '../../Pages/api/chefs';
import { listCities } from '../../Pages/api/cities';
import { uploadImageToS3 } from '../../Helper/awsHelper';
import Multiselect from '../../Components/Multiselect';
import CropImage from '../../Components/Signup/Croper';
import { AuthContext } from '../../contextApi/authContext';
import { getDetails } from '../../Pages/api/chefs';
import moment from 'moment';
import YearPicker from '../../Components/DatePicker/datePicker';


const EditChefSignUp = (props) => {
    const Router = useNavigate()
    const [cityData, setCityData] = useState([])
    const [profileImg, setProfileImg] = useState('')
    const [cropOpen, setCropOpen] = useState(false)
    const [selecetedCuisines, setSelectedCuisines] = useState([])
    const [selectedPositions, setSelectedPositions] = useState([])
    const { userData } = useContext(AuthContext)
    const [chefDetails, setChefDetails] = useState({})
    const [initialVal, setIntialVal] = useState({})
    const [dateofYear, setDateOfYear] = useState(null)

    console.log("Ddddddddddddd", userData)
    useEffect(() => {
        const getChefDetails = async () => {
            const result = await getDetails(userData.id)
            if (!result.error) {
                setChefDetails(result.chefData)
            } else {
                toast.error(result.title)
            }
        }
        if (userData.id) {
            getChefDetails()
        }
    }, [userData])

    const positions = [
        { value: 'chef', label: 'Chef' },
        { value: 'home_cook', label: 'Home Cook' },
        { value: 'kitchen_helper', label: 'Kitchen Helper' },
        { value: 'bartender', label: 'Bartender' },
        { value: 'waiter', label: 'Waiter' },
        { value: 'baking_specialist', label: 'Baking Specialist' },
        { value: 'housekeeping', label: 'Housekeeping' },
        { value: 'manager', label: 'Manager' },
        { value: 'receptionist', label: 'Receptionist' },
        { value:'delivery_staff', label:"Delivery staff"},
        { value:'cafe_staff', label:"Cafe staff"}
    ]

    const handleSubmit = async (values) => {
        try {
            values.id = chefDetails._id
            if ((!chefDetails || !chefDetails.profile_img) && (!profileImg || !profileImg.preview)) {
                return toast.error("Please select profile image")
            }

            // if ((chefDetails && chefDetails.position && (chefDetails.position.length > 0) && (chefDetails.position.includes('chef') || chefDetails.position.includes('cuisine_specialist'))) && (!selecetedCuisines || selecetedCuisines.length < 1)) {
            //     return toast.error("Please select minimum 1 cuisine")
            // }
            // const cuisine = selecetedCuisines.map(v => v.value)
            const position = selectedPositions.map(v => v.value)
            const profile_img = (profileImg && profileImg.preview) ? await uploadImageToS3(`chefs/${values.email}/profile/${profileImg.name}`, profileImg.type, profileImg.preview) : chefDetails.profile_img
            values.profile_img = profile_img
            // values.cuisine = cuisine
            values.position = position
            values.dob = dateofYear ? moment(dateofYear).format('YYYY-MM-DD') : values.dob
            const result = await updateChef(values)
            if (!result.error) {
                toast.success(result.title || "Profile updated successfully")
                resetForm()
                Router("/my-account")
            } else {
                toast.error(result.title)
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong')
        }
    }
    const getCities = async () => {
        const result = await listCities()
        if (!result.error) {
            setCityData(result.citiesData || [])
        } else {
            toast.error(result.title)
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const handleImageChange = async (e, type) => {
        let updatedFile = {};
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0]
            updatedFile = {
                ...file,
                preview: await toBase64(file),
                name: file.name,
                type: file.type
            }
        }
        if (type == 'profile') {
            setProfileImg(updatedFile)
            if (updatedFile && updatedFile.preview) {
                setCropOpen(true)
            }
        }
    }
    useEffect(() => {
        getCities()
    }, [])



    const validationSchema = ({
        name: Yup.string().required("Please enter your name"),
        email: Yup.string().email("Plese enter valid email").required("Please enter your email"),
        phone_no: Yup.string().min(10).max(10).required("Please enter your phone number"),
        dob: Yup.date().required("Please enter your date of birth"),
        gender: Yup.string().required("Please enter your gender"),
        location: Yup.string().required("Please enter your location"),
        address: Yup.string().required("Please enter your address"),
        visit_rate: Yup.string().required("Please enter your visit rate"),
        experience: Yup.string().required("Please enter your experience"),
        // marital_status: Yup.string().required("Please enter your marital status"),
        about: Yup.string().required("Please enter about you"),
        cuisine: Yup.string().required("Please enter your cuisine"),
        job_type: Yup.string().required("Please enter your Job Type"),

    })

    const cuisines = [
        { value: 'indian', label: 'Indian' },
        { value: 'japanese', label: 'Japanese' },
        { value: 'mexican', label: 'Mexican' },
        { value: 'american', label: 'American' },
        { value: 'greek', label: 'Greek' }
    ]

    useEffect(() => {
        setIntialVal({
            name: chefDetails && chefDetails.name ? chefDetails.name : '',
            email: chefDetails && chefDetails.email ? chefDetails.email : '',
            phone_no: chefDetails && chefDetails.phone_no ? chefDetails.phone_no : '',
            dob: chefDetails && chefDetails.dob ? moment(chefDetails.dob).format('YYYY-MM-DD') : '',
            gender: chefDetails && chefDetails.gender ? chefDetails.gender : '',
            location: chefDetails && chefDetails.location ? chefDetails.location._id : '',
            address: chefDetails && chefDetails.address ? chefDetails.address : '',
            visit_rate: chefDetails && chefDetails.visit_rate ? chefDetails.visit_rate : '',
            experience: chefDetails && chefDetails.experience ? chefDetails.experience : '',
            job_type: chefDetails && chefDetails.job_type ? chefDetails.job_type : '',
            about: chefDetails && chefDetails.about ? chefDetails.about : '',
            cuisine:  chefDetails && chefDetails.cuisine ? chefDetails.cuisine : ''
            // relocate: chefDetails && (chefDetails.relocate == true || chefDetails.relocate == false) ? chefDetails.relocate : '',
        })
        setDateOfYear(chefDetails && chefDetails.dob ? moment(chefDetails.dob).format('YYYY-MM-DD') : '',)
        // if (chefDetails && chefDetails.cuisine) {
        //     const cuisine = cuisines.filter(c => chefDetails.cuisine.includes(c.value))
        //     setSelectedCuisines(cuisine)
        // }
        if (chefDetails && chefDetails.position) {
            const position = positions.filter(c => chefDetails.position.includes(c.value))
            setSelectedPositions(position)
        }
    }, [chefDetails])
    return (
        <>

            <Container className='mt-5'>
                <Row className="justify-center checkout-page">
                    <Col xl={{ span: 8 }} md={12} className="lg:px-[10px] md:px-[15px]">
                        <div style={{ boxShadow: '0 0 15px rgba(0, 0, 0, 0.08)' }} className="p-16 bg-white  border-mediumgray mt-[-30px] md:p-10">
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialVal}
                                validationSchema={Yup.object().shape(
                                    validationSchema
                                )}
                                onSubmit={async (values, actions) => {
                                    handleSubmit(values, actions)

                                }}
                            >
                                {({ isSubmitting, status, handleChange, values, getFieldMeta }) => (
                                    <Form>{ console.log("Ffffffffffff", values)}
                                        <div className="d-flex justify-start">
                                            <div>
                                                <img className='w-50 mb-3' height={100} src={profileImg && profileImg.preview ? profileImg.preview : chefDetails && chefDetails.profile_img ? chefDetails.profile_img : "assets/img/Images/avtar28.webp"} onClick={() => { document.getElementById("profile_image").click() }} alt="Profile Image" />
                                                <input
                                                    type="file"
                                                    id="profile_image"
                                                    multiple={false}
                                                    className="d-none"
                                                    accept="image/png, image/jpeg, image/jpg"
                                                    onChange={(e) => handleImageChange(e, 'profile')}
                                                />
                                            </div>
                                        </div>

                                        <Input showErrorMsg={false} name="name" type="text" labelClass="mb-[20px]" label={<div className="mb-[15px]">Full name <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your full name" />
                                        <Input showErrorMsg={false} name="email" type="email" labelClass="mb-[20px]" label={<div className="mb-[15px]">Email <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your email" />
                                        <Input showErrorMsg={false} name="phone_no" type="number" labelClass="mb-[20px]" label={<div className="mb-[15px]">Phone number <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your phone number" />
                                        <YearPicker setDateOfYear={setDateOfYear} dateofYear={dateofYear} classes={`${getFieldMeta(values.dob).error && getFieldMeta(values.dob).error.dob && getFieldMeta(values.dob).touched ? " dangerColorRed " : ''} py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial]`} />
                                        {/* <Input showErrorMsg={false} name="dob" type="date" labelClass="mb-[20px]" label={<div className="mb-[15px]">DOB <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Date of birth" /> */}
                                        <label className="mb-[15px]" htmlFor="contry">Gender <span className="text-red-500">*</span></label>
                                        <select name="gender" id="contry-2" className={`${getFieldMeta(values.gender).error && getFieldMeta(values.gender).error.gender && getFieldMeta(values.gender).touched ? " dangerColorRed " : ''} py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial] bg-white`}
                                            onChange={handleChange}
                                            value={values.gender}
                                        >
                                            <option>Select a Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                        <Row className="justify-center checkout-page">
                                            <Col xl={12} md={12} className="lg:px-[30px] md:px-[15px] mb-4">
                                                <label className="mb-[15px]" htmlFor="">Position <span className="text-red-500">*</span></label>
                                                <Multiselect
                                                    selectedOptions={selectedPositions}
                                                    setSelectedOptions={setSelectedPositions}
                                                    options={positions}
                                                    placeholder="Position"
                                                    className="chef-position"
                                                    maxLimit={2}
                                                />
                                            </Col>
                                            <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                <label className="mb-[15px]" htmlFor="contry">State <span className="text-red-500">*</span></label>
                                                <select name="location" id="contry-2" className={`${getFieldMeta(values.location).error && getFieldMeta(values.location).error.location && getFieldMeta(values.location).touched ? " dangerColorRed " : ''} py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial] bg-white`}
                                                    onChange={handleChange}
                                                    value={values.location}
                                                >
                                                    <option value=""> Current State</option>
                                                    {
                                                        cityData.map(v => {
                                                            return <option value={v._id} key={v._id}> {v.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </Col>
                                            <Col xl={6} md={12} className="lg:px-[30px] md:px-[15px]">
                                                <Input showErrorMsg={false} name="experience" type="text" labelClass="mb-[20px]" label={<div className="mb-[15px]">Experience <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your username" />
                                            </Col>
                                        </Row>
                                        <TextArea showErrorMsg={false} name="address" labelClass="mb-[20px]" label={<div className="mb-[15px]">Address <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Address" />
                                        <Row className="justify-center checkout-page">
                                            <Col xl={6} md={12} className="lg:px-[30px] md:px-[15px]">
                                                <Input showErrorMsg={false} name="visit_rate" type="text" labelClass="mb-[20px]" label={<div className="mb-[15px]">Salary <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your Salary" />
                                            </Col>

                                            <Col xl={6} md={12} className="lg:px-[30px] md:px-[15px]">
                                                <label className="mb-[15px]" htmlFor="job_type1">Job type <span className="text-red-500">*</span></label>
                                                <select value={values.job_type} name="job_type" id="job_type1" className={`${getFieldMeta(values.job_type).error && getFieldMeta(values.job_type).error.job_type && getFieldMeta(values.job_type).touched ? " dangerColorRed " : ''} py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial] bg-white`}
                                                >
                                                    <option value =''>Select Job type</option>
                                                    <option value="part_time"> Part Time</option>
                                                    <option value="full_time">Full Time</option>
                                                    <option value="hourly">Hourly</option>
                                                </select>
                                            </Col>

                                        </Row>
                                        <TextArea showErrorMsg={false} name="about" labelClass="mb-[20px]" label={<div className="mb-[15px]">About me <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="About me" />

                                        <Row className="justify-center checkout-page">

                                            <Col xl={12} md={12} className="lg:px-[30px] md:px-[15px] mb-4">
                                                
                                            <TextArea showErrorMsg={false} name="cuisine" labelClass="mb-[20px]" label={<div className="mb-[15px]">Cuisines <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Cuisines" />
                                                {/* <label className="mb-[15px]" htmlFor="contry">Cuisines <span className="text-red-500">*</span></label> */}
                                                {/* <Multiselect
                                                    selectedOptions={selecetedCuisines}
                                                    setSelectedOptions={setSelectedCuisines}
                                                    options={cuisines}
                                                    placeholder="Cuisines"
                                                    className="cuisine-dropdown"
                                                /> */}

                                            </Col>
                                        </Row>
                                        <Buttons ariaLabel="register" type="submit" className="btnImp  mt-4  btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" color="#fff" themeColor="#fff" size="lg" title="Submit" />
                                    </Form>

                                )}
                            </Formik>
                            <CropImage cropOpen={cropOpen} setCropOpen={setCropOpen} image={profileImg} setImage={setProfileImg} />
                        </div>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default EditChefSignUp
