import React, { useRef, useEffect, useState } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { m } from 'framer-motion';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from 'yup';
// Components
import { fadeIn } from '../../Functions/GlobalAnimations';
import { InputField, resetForm, sendEmail } from "../../Functions/Utilities";
import Buttons from '../../Components/Button/Buttons'
import { Checkbox, Input, TextArea } from '../../Components/Form/Form'
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from "../../Components/Header/Header";
import { Navbar } from 'react-bootstrap';
import FooterStyle01 from '../../Components/Footers/FooterStyle01';
import { toast } from 'react-toastify';
import { createChef } from '../api/chefs';
import { listCities } from '../api/cities';
import { uploadImageToS3 } from '../../Helper/awsHelper';
import Multiselect from '../../Components/Multiselect';
import CropImage from '../../Components/Signup/Croper';
import YearPicker from '../../Components/DatePicker/datePicker';
import moment from 'moment';
const ChefSignUp = (props) => {
    const Router = useNavigate()
    const [cityData, setCityData] = useState([])
    const [dishImages, setDishImages] = useState([])
    const [aadharFrontImg, setAadharFrontImg] = useState({})
    const [aadharBackImg, setAadharBackImg] = useState({})
    const [panImg, setPanImg] = useState({})
    const [profileImg, setProfileImg] = useState('')
    const [cropOpen, setCropOpen] = useState(false)
    const [selectedPositions, setSelectedPositions] = useState([{ value: 'chef', label: 'Chef' }])
    const [isOpen, setIsOpen] = useState(false)
    const [selecetedCuisines, setSelectedCuisines] = useState([])
    const [cropperImage, setCropperImage] = useState({})
    const [cropperType, setCropperType] = useState('')
    const [dateofYear, setDateOfYear] = useState(null)

    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSubmit = async (values, actions) => {

        try{
        // if (!aadharFrontImg || !aadharFrontImg.preview) {
        //     return toast.error("Please select Aadhar front image")
        // }
        // if (!aadharBackImg || !aadharBackImg.preview) {
        //     return toast.error("Please select Aadhar back image")
        // }
        // if (!panImg || !panImg.preview) {
        //     return toast.error("Please select PAN image")
        // }
        if (!profileImg || !profileImg.preview) {
            return toast.error("Please select profile image")
        }
        if (!selectedPositions || selectedPositions.length < 1 || selectedPositions.length > 2) {
            return toast.error("Please select minimum 1 and maximum 2 positions")
        }
        const position = selectedPositions.map(v => v.value)

        if ((position.includes('chef') || position.includes('cuisine_specialist')) && (!selecetedCuisines )) {
            return toast.error("Please select minimum 1 cuisine")
        }
        // const cuisine = selecetedCuisines.map(v => v.value)

        if ((position.includes('chef') || position.includes('cuisine_specialist')) && (!dishImages || dishImages.length < 1)) {
            return toast.error("Please select dish images")
        }

        if ((position.includes('chef') || position.includes('cuisine_specialist')) && dishImages.length > 3) {
            return toast.error("You can select maximum 3 dish images")
        }

        const dish_images = []
        for (const file of dishImages) {
            const url = await uploadImageToS3(`chefs/${values.email}/dishes/${file.name}`, file.type, file.preview)
            dish_images.push(url)
        }
        // const aadhar_front_img = await uploadImageToS3(`chefs/${values.email}/aadhar/${aadharFrontImg.name}`, aadharFrontImg.type, aadharFrontImg.preview)
        // const aadhar_back_img = await uploadImageToS3(`chefs/${values.email}/aadhar/${aadharBackImg.name}`, aadharBackImg.type, aadharBackImg.preview)
        // const pan_img = await uploadImageToS3(`chefs/${values.email}/pan/${panImg.name}`, panImg.type, panImg.preview)
        const profile_img = await uploadImageToS3(`chefs/${values.email}/profile/${profileImg.name}`, profileImg.type, profileImg.preview)
        values.dish_images = dish_images
        // values.aadhar_front_img = aadhar_front_img
        // values.aadhar_back_img = aadhar_back_img
        // values.pan_img = pan_img
        values.profile_img = profile_img
        values.position = position
        // values.cuisine = cuisine
        values.dob = moment(dateofYear).format("YYYY-MM-DD")
        const result = await createChef(values)
        if (!result.error) {
            toast.success("Information submitted successfully" || result.title)
            resetForm(actions)
            Router("/jobs")
        } else {
            toast.error(result.title)
        }
    }catch(err){
        console.log("Ddddddddddddddddddddd", err)
        return toast.error("Something went wrong")
    }
    }
    const getCities = async () => {
        const result = await listCities()
        if (!result.error) {
            setCityData(result.citiesData || [])
        } else {
            toast.error(result.title)
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleDishChange = async (e) => {
        let updatedFiles = [...dishImages];
        if (e.target.files && ((e.target.files.length + (updatedFiles && updatedFiles.length ? updatedFiles.length : 0)) > 3)) {
            return toast.error("You can select 3 images only")
        }
        let imageForCropper = ''
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0]
            imageForCropper = {
                ...file,
                preview: await toBase64(file),
                name: file.name,
                type: file.type
            }
        }
        if (imageForCropper && imageForCropper.preview) {
            setCropperImage(imageForCropper)
            setCropperType('dish')
            setCropOpen(true)
        }
    }
    const handleRemoveDish = (img) => {
        let updatedFiles = dishImages.filter(v => v != img)
        setDishImages(updatedFiles)
    }
    const handleImageChange = async (e, type) => {
        let updatedFile = {};
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0]
            updatedFile = {
                ...file,
                preview: await toBase64(file),
                name: file.name,
                type: file.type
            }
        }
        if (type == 'aadhar_front') {
            setAadharFrontImg(updatedFile)
        } else if (type == 'aadhar_back') {
            setAadharBackImg(updatedFile)
        } else if (type == 'profile') {
            if (updatedFile && updatedFile.preview) {
                setCropperImage(updatedFile)
                setCropperType('profile')
                setCropOpen(true)
            }
        } else {
            setPanImg(updatedFile)
        }
    }
    const setImage = (img) => {
        if (cropperType == 'profile') {
            setProfileImg(img)
        }
        if (cropperType == 'dish') {
            setDishImages([...dishImages, img])
        }
        setCropperImage({})
        setCropperType('')
    }
    useEffect(() => {
        getCities()
    }, [])
    const InitailVal = {
        name: '',
        email: '',
        phone_no: '',
        dob: '',
        gender: '',
        location: '',
        password: '',
        address: '',
        visit_rate: '',
        experience: '',
        marital_status: '',
        about: '',
        relocate: '',
        cuisine:''
    }
    const validationSchema = {
        name: Yup.string().required("Please enter your name"),
        email: Yup.string().email("Plese enter valid email").required("Please enter your email"),
        phone_no: Yup.string().min(10).max(10).required("Please enter your phone number"),
        // dob: Yup.date().required("Please enter your date of birth"),
        gender: Yup.string().required("Please enter your gender"),
        location: Yup.string().required("Please enter your location"),
        password: Yup.string().required("Please enter your password").min(8, 'Password must be 8 characters long'),
        confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required("Please confirm your password"),
        address: Yup.string().required("Please enter your address"),
        visit_rate: Yup.string().required("Please enter your visit rate"),
        experience: Yup.string().required("Please enter your experience"),
        cuisine: Yup.string().required("Please enter your cuisine"),
        about: Yup.string().required("Please enter about you"),
        job_type: Yup.string().required("Please enter your Job type"),
    }

    const positions = [
        { value: 'chef', label: 'Chef' },
        { value: 'home_cook', label: 'Home Cook' },
        { value: 'kitchen_helper', label: 'Kitchen Helper' },
        { value: 'bartender', label: 'Bartender' },
        { value: 'waiter', label: 'Waiter' },
        { value: 'baking_specialist', label: 'Baking Specialist' },
        { value: 'housekeeping', label: 'Housekeeping' },
        { value: 'manager', label: 'Manager' },
        { value: 'receptionist', label: 'Receptionist' },
        { value:'delivery_staff', label:"Delivery staff"},
        { value:'cafe_staff', label:"Cafe staff"}
    ]

    const cuisines = [
        { value: 'indian', label: 'Indian' },
        { value: 'japanese', label: 'Japanese' },
        { value: 'mexican', label: 'Mexican' },
        { value: 'american', label: 'American' },
        { value: 'greek', label: 'Greek' }
    ]
    document.title = "Sign up - my kitchen jobs";

   
    return (
        <>
            <Header btn1={true} topSpace={{ desktop: true }} type="always-fixed border-b border-gray">
                <HeaderNav bg1={'#1c84fd'} bg="#1c84fd" fluid="fluid" theme="light" menu="light" className="px-[75px] py-[0px] md:py-[12px] sm:py-[15px] xs:py-[10px] md:pl-0 md:pr-[15px]" containerClass="px-0 sm:px-1 md:px-4 lg:px-8">
                    <Col xs={5} className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0 xs:!pr-0">
                        <Link aria-label="link for" className="flex items-center" to="/">
                            <Navbar.Brand className="inline-block p-0 m-0">
                                {window.innerWidth > 960 ? <img style={{ minWidth: '250px', maxHeight: 'fit-content' }} className="default-logo default-logo min-w-[0px] md:min-w-[260px]" loading="lazy" src='/assets/img/logoblur.jpeg' alt='logo' /> : ''}
                                <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/Skype_Picture_2024_05_23T10_19_30_008Z-removebg-preview.png' alt='logo' />
                                {window.innerWidth <= 960 ? <img style={{ minWidth: '200px', maxHeight: 'fit-content', margin: '15px 0' }} className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/logoblur.jpeg' alt='logo' /> : ''}
                            </Navbar.Brand>
                        </Link>
                    </Col>
                    <Navbar.Toggle className="text-white order-last md:ml-[17px] md:px-[15px] xs:px-0 border-none">
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                        <span className="bg-white navbar-toggler-line"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse className="col-auto col-lg-8 justify-end menu-order px-lg-0 md:!px-0 text-white">
                        <Menu {...props} />
                    </Navbar.Collapse>
                    {/* {localStorage.getItem('token') && <Col xs={5} className="xs:!pl-[0px] col-auto col-lg-2 text-end pe-0 font-size-0">
                        <HeaderCart className="xs:pl-[0px]" />
                    </Col>} */}
                </HeaderNav>
            </Header>
            <m.section className="py-[130px] overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px]"{...fadeIn}>
                <Container>
                    <Row className="justify-center checkout-page">
                        <Col xl={{ span: 12, offset: 1 }} md={12} className="lg:px-[30px] md:px-[15px]">
                            <h6 className="font-serif font-medium pinkText">Sign Up As&nbsp;
                                {selectedPositions && selectedPositions.length > 1 ? `${selectedPositions[0].label} AND ${selectedPositions[1].label}` : selectedPositions && selectedPositions[0] ? selectedPositions[0].label : ''}
                                <button type="button" onClick={() => setIsOpen(!isOpen)} className="btn btn-transparent ms-3"><i className="fas fa-chevron-down"></i></button>
                            </h6>
                            <div ref={dropdownRef}>
                                <Multiselect isOpen={isOpen} selectedOptions={selectedPositions} setSelectedOptions={setSelectedPositions} options={positions} className="chef-dropdown" maxLimit={2} />
                            </div>
                            <div style={{ boxShadow: '0 0 15px rgba(0, 0, 0, 0.08)' }} className="p-16 bg-white border-mediumgray mt-[-30px] md:p-10">
                                <Formik
                                    initialValues={InitailVal}
                                    validationSchema={Yup.object().shape(
                                        validationSchema
                                    )}
                                    onSubmit={async (values, actions) => {
                                        if(dateofYear){
                                            handleSubmit(values, actions)
                                        }
                                    }}
                                >
                                    {({ isSubmitting, status, handleChange, values, getFieldMeta }) => (
                                        <Form>
                                            <Row className="justify-center checkout-page">
                                                <Col xl={12} md={12} className="lg:px-[30px] md:px-[15px]">
                                                    <div className="d-flex justify-start cursor-pointer">
                                                        <div>
                                                            <img className='w-50 mb-3' height={100} src={profileImg && profileImg.preview ? profileImg.preview : values && values.profile ? values.profile : "assets/profilePicture.jpg"} onClick={() => { document.getElementById("profile_image").click() }} alt="Profile Image" />
                                                            <input
                                                                type="file"
                                                                id="profile_image"
                                                                multiple={false}
                                                                className="d-none"
                                                                accept="image/png, image/jpeg, image/jpg"
                                                                onChange={(e) => handleImageChange(e, 'profile')}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <Input onChange={(e) => handleImageChange(e, 'profile')} showErrorMsg={false} name="file" type="file" labelClass="mb-[20px]" label={<div className="mb-[15px]">Profile Picture <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="please upload your photo" /> */}
                                                </Col>
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                    <Input showErrorMsg={false} name="name" type="text" labelClass="mb-[20px]" label={<div className="mb-[15px]">Full name <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your full name" />
                                                </Col>
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                    <Input showErrorMsg={false} name="email" type="email" labelClass="mb-[20px]" label={<div className="mb-[15px]">Email <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your email" />
                                                </Col>
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                    <Input showErrorMsg={false} name="password" type="password" labelClass="mb-[20px]" label={<div className="mb-[15px]">Password <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your password" />
                                                </Col>
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                    <Input showErrorMsg={false} name="confirm_password" type="password" labelClass="mb-[20px]" label={<div className="mb-[15px]">Confirm Password <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your password" />
                                                </Col>
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                    <label className="mb-[15px]" htmlFor="job_type">Job type <span className="text-red-500">*</span></label>
                                                    <select name="job_type" id="job_type" className={`${getFieldMeta(values.job_type).error && getFieldMeta(values.job_type).error.job_type && getFieldMeta(values.job_type).touched ? " dangerColorRed " : ''} py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial] bg-white`}
                                                        onChange={handleChange}
                                                    >
                                                        <option>Select Job type</option>
                                                        <option value="part_time"> Part Time</option>
                                                        <option value="full_time">Full Time</option>
                                                        <option value="hourly">Hourly</option>
                                                    </select>
                                                </Col>
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                    <Input showErrorMsg={false} name="phone_no" type="number" labelClass="mb-[20px]" label={<div className="mb-[15px]">Phone number <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your phone number" />
                                                </Col>
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                <YearPicker  setDateOfYear={setDateOfYear} dateofYear={dateofYear} classes={`${getFieldMeta(values.dob).error && getFieldMeta(values.dob).error.dob && getFieldMeta(values.dob).touched ? " dangerColorRed " : ''} py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial]`}/>
                                                    {/* <input showErrorMsg={false} name="dob" type="year"
                                                        step="1" id='yearOfBirth'
                                                        placeholder="YYYY" labelClass="mb-[20px]" label={<div className="mb-[15px]">Year Of Birth <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" /> */}
                                                </Col>
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                    <label className="mb-[15px]" htmlFor="contry">Gender <span className="text-red-500">*</span></label>
                                                    <select name="gender" id="contry-2" className={`${getFieldMeta(values.gender).error && getFieldMeta(values.gender).error.gender && getFieldMeta(values.gender).touched ? " dangerColorRed " : ''} py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial] bg-white`}
                                                        onChange={handleChange}
                                                    >
                                                        <option>Select a Gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                    </select>
                                                </Col>

                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                    <label className="mb-[15px]" htmlFor="contry">State <span className="text-red-500">*</span></label>
                                                    <select name="location" id="contry" className={`${getFieldMeta(values.location).error && getFieldMeta(values.location).error.location && getFieldMeta(values.location).touched ? " dangerColorRed " : ''} py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial] bg-white`}
                                                        onChange={handleChange}
                                                        value={values.location}
                                                    >
                                                        <option value=""> State</option>
                                                        {
                                                            cityData.map(v => {
                                                                return <option value={v._id} key={v._id}> {v.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </Col>
                                                <Col xl={6} md={12} className="lg:px-[30px] md:px-[15px]">
                                                    <Input showErrorMsg={false} name="experience" type="text" labelClass="mb-[20px]" label={<div className="mb-[15px]"> Education and Experience <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Education and Experience" />
                                                </Col>
                                                <Col xl={6} md={6} className="lg:px-[30px] md:px-[15px]">
                                                    <TextArea showErrorMsg={false} name="address" labelClass="mb-[20px]" label={<div className="mb-[15px]">City <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="City" />
                                                </Col>
                                                <Col xl={6} md={12} className="lg:px-[30px] md:px-[15px]">
                                                    <TextArea showErrorMsg={false} name="visit_rate" type="text" labelClass="mb-[20px]" label={<div className="mb-[15px]">Salary <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your Salary" />
                                                </Col>
                                            </Row>

                                           
                                            <Row className="justify-center checkout-page">
                                                <Col xl={6} md={12} className="lg:px-[30px] md:px-[15px]">
                                                <TextArea showErrorMsg={false} name="about" labelClass="mb-[20px]" label={<div className="mb-[15px]">About me <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="About me" />
                                                </Col>
                                            <Col xl={6} md={12} className="lg:px-[30px] md:px-[15px]">
                                                    {/* <label className="mb-[15px]" htmlFor="contry">Cuisines <span className="text-red-500">*</span></label> */}

                                                    {
                                                        selectedPositions && selectedPositions[0] && (selectedPositions[0].value == 'chef' || selectedPositions[0].value == 'cuisine_specialist' || (selectedPositions[1] && (selectedPositions[1].value == 'chef' || selectedPositions[1].value == 'cuisine_specialist'))) &&
                                                        <TextArea showErrorMsg={false} name="cuisine" type="text" labelClass="mb-[20px]" label={<div className="mb-[15px]">Cuisines <span className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]" placeholder="Cuisines" />
                                                        // <Multiselect
                                                        //     selectedOptions={selecetedCuisines}
                                                        //     setSelectedOptions={setSelectedCuisines}
                                                        //     options={cuisines}
                                                        //     placeholder="Cuisines"
                                                        //     className="cuisine-dropdown"
                                                        // />

                                                    }
                                                </Col>
                                                {/* <Col xl={6} md={12} className="lg:px-[30px] md:px-[15px]">
                                                    <label className="mb-[15px]" htmlFor="contry">Willing to relocate <span className="text-red-500">*</span></label>
                                                    <select name="relocate" id="contry-2" className={`${getFieldMeta(values.relocate).error && getFieldMeta(values.relocate).error.relocate && getFieldMeta(values.relocate).touched ? " dangerColorRed " : ''} py-[13px] px-[15px] text-[14px] border border-[#dfdfdf] w-full mb-[25px] leading-[initial]`}
                                                        onChange={handleChange}>
                                                        <option value=""> Willing to relocate</option>
                                                        <option value="yes"> Yes</option>
                                                        <option value="no">No</option>

                                                    </select>
                                                </Col> */}
                                              
                                            </Row>
                                            <div className="">
                                                {
                                                    selectedPositions && selectedPositions[0] && (selectedPositions[0].value == 'chef' || selectedPositions[0].value == 'cuisine_specialist' || (selectedPositions[1] && (selectedPositions[1].value == 'chef' || selectedPositions[1].value == 'cuisine_specialist'))) &&
                                                    <div className="mb-[30px] mt-2">
                                                        <h6 className="mb-2">Add some pictures to your best dishes: </h6>
                                                        <div className="d-flex justify-content-start">
                                                            {
                                                                dishImages.map((v, i) => {
                                                                    return (
                                                                        <div key={i} className="me-2 position-relative" style={{ width: '100px', height: '100px' }}>
                                                                            <img src={v.preview} className="d-block w-100 h-100" alt="dish" />
                                                                            <button className="position-absolute top-0 end-0 btn rounded-0 text-[11px] btn-danger" onClick={() => handleRemoveDish(v)}><i className="fas fa-times" /></button>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            <div>
                                                                <button disabled={dishImages && dishImages.length >= 3} type="button" onClick={() => { document.getElementById("dish_images").click() }} className="btn border text-[13px]">
                                                                    <i className="fas fa-plus" />
                                                                </button>
                                                                <input
                                                                    type="file"
                                                                    id="dish_images"
                                                                    multiple={false}
                                                                    className="d-none"
                                                                    accept="image/png, image/jpeg, image/jpg"
                                                                    onChange={handleDishChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {/* <div className="mb-[30px]">
                                                    <h6 className="openSans">Add your legal documents: </h6>
                                                    <div>
                                                        <button type="button" onClick={() => { document.getElementById("aadhar_front").click() }} className="btn btn-sm btn-transparent p-0">
                                                            {aadharFrontImg && aadharFrontImg.preview ? <i className="fas fa-check text-success me-2" /> : <i className="fas fa-times text-danger me-2" />}
                                                            Aadhar card front
                                                        </button>
                                                        <input
                                                            type="file"
                                                            id="aadhar_front"
                                                            multiple={false}
                                                            className="d-none"
                                                            accept="image/png, image/jpeg, image/jpg"
                                                            onChange={(e) => handleImageChange(e, 'aadhar_front')}
                                                        />
                                                    </div>
                                                    <div>
                                                        <button type="button" onClick={() => { document.getElementById("aadhar_back").click() }} className="btn btn-sm btn-transparent p-0">
                                                            {aadharBackImg && aadharBackImg.preview ? <i className="fas fa-check text-success me-2" /> : <i className="fas fa-times text-danger me-2" />}
                                                            Aadhar card back
                                                        </button>
                                                        <input
                                                            type="file"
                                                            id="aadhar_back"
                                                            multiple={false}
                                                            className="d-none"
                                                            accept="image/png, image/jpeg, image/jpg"
                                                            onChange={(e) => handleImageChange(e, 'aadhar_back')}
                                                        />
                                                    </div>
                                                    <div>
                                                        <button type="button" onClick={() => { document.getElementById("pan").click() }} className="btn btn-sm btn-transparent p-0">
                                                            {panImg && panImg.preview ? <i className="fas fa-check text-success me-2" /> : <i className="fas fa-times text-danger me-2" />}
                                                            PAN card
                                                        </button>
                                                        <input
                                                            type="file"
                                                            id="pan"
                                                            multiple={false}
                                                            className="d-none"
                                                            accept="image/png, image/jpeg, image/jpg"
                                                            onChange={(e) => handleImageChange(e, 'pan')}
                                                        />
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className='flex'>
                                                <Buttons ariaLabel="register" type="submit" className="btnImp btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" themeColor='#fff' color="#fff" size="lg" title="Register" />

                                                <Link className={`nav-link ml-2 `} to={'/chef-login'}>{
                                                    <button class=" btnImp text-black rounded-[50px] bg-transparent px-5 py-3 flex items-center space-x-2 transition duration-300">
                                                        <span className="text-white font-medium text-[13px]">SIGN IN</span>
                                                        <span class="text-white text-[13px] transform transition-transform duration-300 group-hover:translate-x-1 fas fa-arrow-left"></span>
                                                    </button>
                                                }</Link>
                                            </div>

                                            {/* <div className='flex justify-content-end'>
                                                <div className='mt-4 text-right'>
                                                    <p className='text-black'>
                                                        <p className='inline'> Already have an account? </p> <Buttons href="/chef-login" className="text-right text-[12px] btn-link after:bg-[#000] font-medium font-serif uppercase btn after:h-[2px] md:text-md !p-[0px 0 2px]" size="md" color="#fff" title="Sign in" />
                                                    </p>
                                                </div>
                                            </div> */}
                                        </Form>

                                    )}
                                </Formik>
                                <CropImage cropOpen={cropOpen} setCropOpen={setCropOpen} image={cropperImage} setImage={setImage} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </m.section>
            <FooterStyle01 theme="dark" className="text-slateblue bg-[#262b35]" />
        </>
    )
}

export default ChefSignUp
