import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
const MyFormComponent = ({setDateOfYear,dateofYear, classes}) => {
  return (
    <div>
      <label className="mb-[15px]" htmlFor="contry"> Year of birth <span className="text-red-500">*</span></label>
      <DatePicker
        value={dateofYear}
        onChange={setDateOfYear}
        view="decade"
        maxDetail="decade"
        minDetail="decade"
        className={`customform-control ${dateofYear?'':'border !border-[red]'}` } 
      />
    </div>

  );
}

export default MyFormComponent;
