import React, { useContext, useEffect, useRef, useState } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { m } from 'framer-motion';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// Components
import Buttons from '../../Components/Button/Buttons'
import { Checkbox, Input, TextArea } from '../../Components/Form/Form'
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from "../../Components/Header/Header";
import { toast } from 'react-toastify';
import { listCities } from '../../Pages/api/cities';
import { AuthContext } from '../../contextApi/authContext';
import { getUserDetails, resend_otp, updateUser } from '../../Pages/api/users';
import { resetForm } from '../../Functions/Utilities';
import { getUserJobs } from '../../Pages/api/jobs';
import JobCard from '../IconWithText/JobCard';
import InteractiveBannersUser from '../InteractiveBanners/interactiveBannersUser';
import { addToWishlist ,staffWistlists} from '../../Pages/api/wishlists';
import { fadeIn } from '../../Functions/GlobalAnimations';
const Staffwishlist = (props) => {
    const Router = useNavigate() 
    const [loading, setLoading] = useState(false)
    const { userData } = useContext(AuthContext)
    const [chefData, setChefData] = useState([])
    const wishlistfn = async () => {
        let data = await staffWistlists()
        setChefData(data?.data?.chef_id)
    }
    useEffect(()=>{
        wishlistfn()
    },[])

    return (
        <>
                <Container>
                    <Row className="justify-center checkout-page">
                        <Col xl={{ span: 9 }} md={12} className="px-[20px] lg:px-[10px] md:px-[15px]">
                            <div className=" border-mediumgray lg:mt-[35px]">
                              <InteractiveBannersUser
                               toast={toast}
                               wishlistfn={wishlistfn}
                               addToWishlist={addToWishlist}
                               grid="row-cols-1 gap-y-10"
                               overlay={["#2ed47ce6", "#e8a626e6"]}
                               animation={fadeIn}
                               ListingData={chefData}
                               loading={loading}
                              />
                            </div>
                        </Col>
                    </Row>
                </Container>
        </>
    )
}

export default Staffwishlist
