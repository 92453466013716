import React from 'react';

const Modal = ({ bgClass, title,isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-10 z-50">
      <div className={`${bgClass} rounded-lg shadow-lg w-5/12 sm:w-11/12 md:w-7/12 lg:w-5/12 xl:w-5/12  max-h-full`}>
        <div className="px-3 py-4 flex justify-between items-center border-b">
        <div className="flex-1 text-center">
        <p className="text-[1.8rem] font-semibold text-center">{title}</p>
          </div>
          {/* <p className="text-lg font-semibold text-center">{title}</p> */}
          <button onClick={onClose} className="text-[22px] text-gray-400 hover:text-gray-600">&times;</button>
        </div>
        <div className="p-4 overflow-y-auto max-h-[40rem]">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
