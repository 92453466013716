import React, { memo } from 'react'

// Data
import { ListData01 } from './ListsData'

// Libraries
import { m } from 'framer-motion';
import { PropTypes } from "prop-types";
import { Col, Row } from 'react-bootstrap';

const Lists = (props) => {
    return (
        <ul className={`${props.theme}${props.className ? ` ${props.className}` : ""}`}>
              <m.li style={{display:window.innerWidth>768?"block":'none' }}  {...{ ...props.animation, transition: { delay: (1) * props.animationDelay, initial: { opacity: 0, x: "100%" }, ease: [0.25, 1, 0.5, 1], duration: 1.2 } }}>
                            <Row>
                                <Col md={5} lg={5}>
                                 {/* <i className={'feather-arrow-right-circle inline'}></i> */}
                                 <span className='block list-title font-semibold inline text-[1.05rem]'>Name</span>
                                </Col>
                                <Col md={4}  lg={4}>
                               <span className='block list-title font-semibold text-[1.05rem]'>Phone no</span>
                                </Col>
                                <Col md={3}  lg={3}>
                                 <span className='list-number font-semibold text-[1.05rem]'>Salary</span>
                                </Col>
                            </Row>
                           
                        </m.li>
            {
                props.data.map((item, i) => {
                    return (
                        <m.li style={{display:"block"}} key={i}  {...{ ...props.animation, transition: { delay: (i+1) * props.animationDelay, initial: { opacity: 0, x: "100%" }, ease: [0.25, 1, 0.5, 1], duration: 1.2 } }}>
                            <Row>
                                <Col sm={10} md={5} lg={5}>
                                 {/* <i className={'feather-arrow-right-circle'}></i> */}
                               { window.innerWidth < 768?<span className='block list-title font-semibold inline text-[1.05rem]  mr-3'>Name:</span>:''}
                                {item?.chef_id?.name&& <a aria-label="list" className='text-[1.05rem]' href={`chef-details?id=${item.chef_id._id}`}>{item.chef_id.name}</a>}
                                </Col>
                                <Col sm={10} md={4}  lg={4}>
                                { window.innerWidth < 768?<span className='block list-title font-semibold inline text-[1.05rem] mr-3'>Phone:</span>:''}
                                {item?.chef_id?.phone_no && <span className='list-title text-[1.05rem]'>{item?.chef_id?.phone_no}</span>}
                                </Col>
                                <Col  sm={10} md={3}  lg={3}>
                                { window.innerWidth < 768?<span className='block list-title font-semibold inline text-[1.05rem]  mr-3'>Salary:</span>:''}
                                {item.chef_id.visit_rate && <span className='list-number text-[1.05rem]'>{item.chef_id.visit_rate}</span>}
                                </Col>
                            </Row>
                           
                        </m.li>
                    )
                })
            }
        </ul>
    )
}

Lists.defaultProps = {
    data: ListData01,
    theme: "list-style-01",
    animationDelay: 0.1
}

Lists.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.exact({
            title: PropTypes.string,
            content: PropTypes.string,
            link: PropTypes.string,
            icon: PropTypes.string,
            linkTitle: PropTypes.string,
            number: PropTypes.string,
        })
    ),
    animation: PropTypes.object,
    animationDelay: PropTypes.number,
    theme: PropTypes.string,
}

export default memo(Lists)