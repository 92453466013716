import React, { memo, useEffect } from 'react'

//Libraries
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { m } from 'framer-motion'
import { PropTypes } from "prop-types";
import { Spinner } from "react-bootstrap";
// Components
import Buttons from '../Button/Buttons'

// Data
import { InteractiveBannersData03 } from './InteractiveBannersData'

const InteractiveBannersForUser = (props) => {

    const handleAdd = async (chefId) => {
        let value = { chefId }
        let data = await props.addToWishlist(value)
        props.toast.success(data.title)
        props.wishlistfn()
    }

    const style = { "--overlay-color": typeof (props.overlay) === "object" ? `linear-gradient(to right top, ${props.overlay.map((item, i) => item)})` : props.overlay }
    return (
        <>
            {
                props.loading ? <>
                    <div className="row justify-content-center g-0 relative overflow-hidden rounder-[6px]" >
                        <div
                            class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status">
                            <span
                                class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                            >Loading...
                            </span>

                        </div>
                    </div>
                </>
                    :
                    !props.ListingData || props.ListingData.length < 1 ? <div className="row justify-content-center g-0 relative overflow-hidden rounder-[6px]" >
                        <div className="mb-80 row justify-content-center text-[18px]"> No staff have added to your wishlist.</div></div> :
                        <Row className={`${props.grid ? props.grid : ""}${props.className ? ` ${props.className} justify-content-center` : ""}`} lg={3} sm={2}>
                            {props.ListingData && props.ListingData.map((item, i) => {
                                return (
                                    <m.div key={i} className="col" {...{ ...props.animation, transition: { delay: i * props.animationDelay } }}>
                                        <div className="interactivebanners-style-03 g-0 relative overflow-hidden rounder-[6px]" style={style}>
                                            {item.profile_img && (
                                                <Link aria-label="link" to={`/chef-details?id=${item._id}`}>
                                                    <div className="interactivebanners-box-image relative overflow-hidden">
                                                        {item.profile_img && <img width={100} height={100} className="interactivebanners-img w-full relative overflow-hidden z-1" src={item && item.profile_img ? item.profile_img : 'https://via.placeholder.com/700x700'} alt="interactivebanners" />}
                                                        {/* <div className="interactivebanners-btn border-2 border-transparent flex justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[44px] w-[44px] leading-10 p-0 z-[1] rounded-full text-center text-black">
                                                            <i className={`fas fa-arrow-right flex items-center`}></i>
                                                        </div> */}

                                                        {/* <div className="interactivebanners-box-hover absolute h-full w-full top-0 left-0"></div> */}
                                                    </div>
                                                </Link>
                                            )}
                                            <div className="bg-white border-2 border-transparent flex absolute top-[5px] right-[5px] justify-center  h-[35px] w-[35px] leading-10 p-0 z-[1] rounded-full text-center text-black">
                                                <i onClick={() => handleAdd(item._id)} style={{ color: 'red' }} className={`fas fa-arrow-right fa-heart flex items-center`}></i>
                                            </div>
                                            {/* <i onClick={() => handleAdd(item._id)} style={{ color:  'red'  }} className="text-[18px] fas fa-arrow-right fa-heart absolute top-[0px] right-0 py-[1px] px-[3px]"></i> */}
                                            <div className="interactivebanners-box-content bg-white text-center p-[20px] pt-[30px] relative lg:p-[20px] xs:p-[25px]">

                                                {/* {i<=4? <div className="backgroundGradient text-small text-white uppercase absolute font-medium text-sm -top-[15px] right-0 py-[5px] px-[20px]"> Sponsored</div>:''} */}
                                                {item.name && <Link aria-label="link" className="interactivebanners-title font-medium text-darkgray font-serif text-md uppercase no-underline">{item.name}</Link>}
                                                {item.position && <p aria-label="link" className="pinkText interactivebanners-title font-medium font-serif text-md capitalize no-underline">
                                                    {item?.position[0] ? item.position[0]?.replace("_", " ") : ''} {item?.position[1] ? ' and ' + item.position[1].replace("_", " ").toUpperCase() : ''}
                                                </p>}
                                                {/* {item.location && <div className="content-hover-effect">
                                                    {item.location && <span className="interactivebanners-subtitle absolute left-0 right-0 h-full w-full transition-default">{item.location.name}</span>}
                                                    {(item.location || item.location.state) &&
                                                        <Buttons ariaLabel="interactive button" className="interactivebanners-box-sub-title relative p-0 font-medium font-serif uppercase after:h-[1px] after:bg-[#2ed47c] btn-link" color="#2ed47c" size="lg" title={item.location.state} />
                                                    }
                                                </div>
                                                } */}
                                            </div>
                                        </div>
                                    </m.div>
                                )
                            })}
                        </Row>
            }
        </>
    )
}


export default memo(InteractiveBannersForUser)
