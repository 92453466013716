import axios from "axios";
import Appconfig from "../../Constant/constant";
const createBooking = async (values) => {
    try {
        const { data } = await axios.post(`${Appconfig.baseurl}/bookings`, values, {
            headers: { token: localStorage.getItem("token") }
        })
        return data;
    } catch (error) {
        return { title: error.message || "Something went wrong", error: true, data: error }
    }
}

const getChefBookings = async (id) => {
    try {
        const { data } = await axios.get(`${Appconfig.baseurl}/bookings/chef_bookings?id=${id}`)
        return data;
    } catch (error) {
        return { title: error.message || "Something went wrong", error: true, data: error }
    }
}

const getDetails = async (values) => {
    try {
        const { id } = values
        const { data } = await axios.get(`${Appconfig.baseurl}/chefs/details?id=${id}`)
        return data;
    } catch (error) {
        return { title: error.message || "Something went wrong", error: true, data: error }
    }
}

const getUserBookings = async (id) => {
    try {
        const { data } = await axios.get(`${Appconfig.baseurl}/bookings?id=${id}`, {
            headers: { token: localStorage.getItem("token") }
        })
        return data;
    } catch (error) {
        return { title: error.message || "Something went wrong", error: true, data: error }
    }
}

const cancelBooking = async (id) => {
    try {
        const { data } = await axios.post(`${Appconfig.baseurl}/bookings/cancel_booking`, { id }, {
            headers: { token: localStorage.getItem("token") }
        })
        return data;
    } catch (error) {
        return { title: error.message || "Something went wrong", error: true, data: error }
    }
}

export {
    createBooking,
    getChefBookings,
    getDetails,
    getUserBookings,
    cancelBooking
}