
const PaginationPro = ({ page, setPage, totalPages, getData }) => {
    return (
      <nav aria-label="Page navigation example">
          <ul className="pagination pagination-style-01 font-sans font-medium items-center">
              {
                  totalPages > 1 &&
                  <li className="page-item">
                      <button className={`page-link ${page == 1 ? 'disabled' : ''}`} onClick={() => {setPage(page-1); getData('', page-1);}} aria-label="Previous">
                      <i className="feather-arrow-left text-lg"></i>
                      </button>
                  </li>
              }
              <li className={`page-item ${page == ((page - 2) > 1 ? page - 2 : 1) ? 'active' : ''}`}><button className="page-link" onClick={() => {setPage((page - 2) > 1 ? page - 2 : 1); getData('', (page - 2) > 1 ? page - 2 : 1)}}>{(page - 2) > 1 ? page - 2 : 1}</button></li>
              {
                  totalPages > 1 &&
                  <li className={`page-item ${page == ((page - 1) > 2 ? page - 1 : 2) ? 'active' : ''}`}><button className="page-link" onClick={() => {setPage((page - 1) > 2 ? page - 1 : 2); getData('', (page - 1) > 2 ? page - 1 : 2);}}>{(page - 1) > 2 ? page - 1 : 2}</button></li>
              }
              {
                  totalPages > 2 &&
                  <li className={`page-item ${page == ((page - 0) > 3 ? page - 0 : 3) ? 'active' : ''}`}><button className="page-link" onClick={() => {setPage((page - 0) > 3 ? page - 0 : 3); getData('', (page - 0) > 3 ? page - 0 : 3);}}>{(page - 0) > 3 ? page - 0 : 3}</button></li>
              }
              {
                  totalPages > 1 &&
                  <li className="page-item">
                      <button className={`page-link ${page == totalPages ? 'disabled' : ''}`} onClick={() => {setPage(page+1); getData('', page+1);}} aria-label="Next">
                      <i className="feather-arrow-right text-lg"></i>
                      </button>
                  </li>
              }
          </ul>
      </nav>
    );
  };
  export default PaginationPro;
  