import { useEffect, useState, useContext } from "react"
import { toast } from "react-toastify"
import { getDetails } from "../../Pages/api/chefs";
import { updateDishImages } from "../../Pages/api/chefs";
import CropImage from "../Signup/Croper";
import { AuthContext } from "../../contextApi/authContext";
import { useNavigate } from "react-router-dom";
import { uploadImageToS3 } from "../../Helper/awsHelper";
import { Button, Col, Container, Row } from "react-bootstrap";
import Buttons from "../Button/Buttons";

const ChefPictures = (props) => {
    const { userData } = useContext(AuthContext)
    const [chefDetails, setChefDetails] = useState({})
    const [dishImages, setDishImages] = useState([])
    const [cropOpen, setCropOpen] = useState(false)
    const [cropperImage, setCropperImage] = useState({})
    const Router = useNavigate()

    useEffect(() => {
        const getChefDetails = async () => {
            const result = await getDetails(userData.id)
            if (!result.error) {
                setChefDetails(result.chefData)
            } else {
                toast.error(result.title)
            }
        }
        if (userData.id) {
            getChefDetails()
        }
    }, [userData])

    useEffect(() => {
        if (chefDetails.dish_images && chefDetails.dish_images.length > 0) {
            setDishImages(chefDetails.dish_images)
        }
        else {
            setDishImages([])
        }
    }, [chefDetails])

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleDishChange = async (e) => {
        let updatedFiles = [...dishImages];
        if (e.target.files && ((e.target.files.length + (updatedFiles && updatedFiles.length ? updatedFiles.length : 0)) > 3)) {
            return toast.error("You can select 3 images only")
        }
        let imageForCropper = ''
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0]
            imageForCropper = {
                ...file,
                preview: await toBase64(file),
                name: file.name,
                type: file.type
            }
        }
        if (imageForCropper && imageForCropper.preview) {
            setCropperImage(imageForCropper)
            setCropOpen(true)
        }
    }

    const handleRemoveDish = (img) => {
        let updatedFiles = dishImages.filter(v => v != img)
        setDishImages(updatedFiles)
    }

    const setImage = (img) => {
        setDishImages([...dishImages, img])
        setCropperImage({})
    }
    // const setImage = (img) => {
    //     if (cropperType == 'profile') {
    //         setProfileImg(img)
    //     }
    //     if (cropperType == 'dish') {
    //         setDishImages([...dishImages, img])
    //     }
    //     setCropperImage({})
    //     setCropperType('')
    // }

    const updateImages = async () => {
        try {
            const dish_images = []
            for (const file of dishImages) {
                if (!file.preview && file.includes('.amazonaws.com')) {
                    dish_images.push(file)
                } else {
                    const url = await uploadImageToS3(`chefs/${chefDetails.email}/dishes/${file.name}`, file.type, file.preview)
                    dish_images.push(url)
                }
            }
            const result = await updateDishImages({ dish_images })
            if (!result.error) {
                toast.success("Dish images updated successfully" || result.title)
                Router('/my-account')
                props.setTab(3)
            } else {
                toast.error(result.title)
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong')
        }
    }

    return (
        <Container className='mt-5'>
            <Row className="d-flex justify-center checkout-page">
                <Col xl={{ span: 9 }} md={12} className="lg:px-[10px] md:px-[15px]">
                    <Row className="flex justify-start checkout-page p-4">
                        {
                            dishImages.map((val) => {
                                return <Col xl={3} md={6} sm={12} className="lg:px-[10px] md:px-[15px] ">
                                    <div style={{ boxShadow: '0 0 15px rgba(0, 0, 0, 0.08)' }} className="bg-white border-mediumgray mt-[-30px] md:p-10">
                                        <div className="mb-2 mt-2 ml-2 mr-2">
                                            <img src={val && val.preview ? val.preview : val ? val : ''} className="w-100" alt="Profile Image" />
                                        </div>
                                        <div onClick={() => { handleRemoveDish(val) }} style={{ color: 'red', cursor: 'pointer', fontWeight: '600' }} className="text-center mb-30 text-[14px] uppercase">Remove</div>
                                    </div>
                                </Col>
                            })
                        }
                        <Col>

                            <button className="btn btn-transparent p-0 border-none p-4" disabled={dishImages && dishImages.length >= 3} onClick={() => { document.getElementById("dish").click() }}><i className="fa fa-plus-circle pinkText" style={{ fontSize: "36px", color: "#0d6efd" }} /></button>
                            <input
                                type="file"
                                id="dish"
                                multiple={false}
                                className="d-none"
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={handleDishChange}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="d-flex justify-center checkout-page mt-4 ">
                <Col xl={{ span: 9 }} md={12} className="lg:px-[10px] md:px-[15px]">
                    <Row className="d-flex justify-start checkout-page mt-4 px-4 ">
                        <Col xl={3} md={6} sm={12}>
                            <Buttons ariaLabel="register" onClick={updateImages} type="submit" className="btnImp  mt-4  btn-fill shadow-[0_0_15px_rgba(0,0,0,0.10)] font-medium btn-fancy font-serif uppercase rounded-[50px] btn-shadow text-[15px]" themeColor="#fff" color="#fff" size="lg" title="Update" />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <CropImage cropOpen={cropOpen} setCropOpen={setCropOpen} image={cropperImage} setImage={setImage} />
        </Container>
    )
}
export default ChefPictures;