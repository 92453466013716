import { createContext, useState, useEffect, } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Appconfig from "../Constant/constant";
const initialState = {
    userData: {
        id: "",
        email: "",
        name: "",
        location: "",
        type: "",
        login_type: "",
    },
    onLogout: async () => { },
    reloadUserData: async (credentials) => { },
    loading: false,
    jobOpen:false
};

const AuthContext = createContext(initialState);

const AuthProvider = ({ children }) => {
    const Router =useNavigate()
    const [userData, setUserData] = useState({
        id: "",
        email: "",
        name: "",
        location: "",
        type: "",
        login_type: "",
    });
    const [loading, setLoading] = useState(true);
    const [jobOpen, setJobOpen] = useState(false);
    const onLogout = async () => {
        setUserData(initialState.userData);
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        };

    const reloadUserData = async () => {
        try {
            const { data } = await axios.post(`${Appconfig.baseurl}/users/token/refresh`, null, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                localStorage.setItem("token", data.token);
                const payload = data.tokenData
                console.log("Ddddddddddddddddddddddddddddddddddd", payload)
                setUserData({
                    id: payload.login_type == "user" ? payload.user_id : payload._id,
                    name: payload.name,
                    email: payload.email,
                    location: payload.location,
                    type: payload.type,
                    login_type: payload.login_type == "user" ? payload.login_type : 'chef',
                });
            }
            else {
                throw new Error(data.title);
            }
        } catch (e) {
            onLogout();
        }

        setLoading(false);
    };

    useEffect(() => {
        const verifyToken = async () => {
            setLoading(true);
            try {
                const { data } = await axios.post(localStorage.getItem("chef") ?`${Appconfig.baseurl}/chefs/token`:`${Appconfig.baseurl}/users/token`, null, {
                    headers: { token: localStorage.getItem("token") }
                });
                if (!data.error) {
                    const payload = data.tokenData
                    setUserData({
                        id: payload.login_type == "user" ? payload.user_id : payload._id,
                        name: payload.name,
                        email: payload.email,
                        location: payload.location,
                        type: payload.type,
                        login_type: payload.login_type == "user" ? payload.login_type:"chef",
                    });
                }
                else {
                    throw new Error(data.title);
                }
            } catch (e) {
                onLogout()
            }
            setLoading(false);
        };

        if (window.location.pathname != "/login" && localStorage.getItem('token')) {
            verifyToken();
        }
        else if (window.location.pathname == "/my_account" && !localStorage.getItem('token')) {
            onLogout()
        }
    }, [Router]);

    const value = {
        userData,
        onLogout,
        reloadUserData,
        loading,
        setJobOpen,
        jobOpen
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
