import React, { memo, useState } from 'react'

// Libraries
import { Row, Col, Tab, Tabs } from 'react-bootstrap'
import { PropTypes } from "prop-types";
import { m } from "framer-motion";

// Components
import Buttons from '../Button/Buttons'
import { fadeIn } from "../../Functions/GlobalAnimations";

// Data
import { TabData01 } from './TabData'
import EditUserForm from '../UserAccountTabContain/UserEditForm';
import ForgotPass from '../UserAccountTabContain/ForgotPassword';
import MyUserBooking from '../UserAccountTabContain/MyBooking';
import MyJobs from '../UserAccountTabContain/MyJobs';
import Staffwishlist from '../UserAccountTabContain/StaffWishlist';

const UserAccountTab = (props) => {
    const [tab, setTab] = useState(1)
    const handleSelect = (selectedTab) => {
        setTab(selectedTab);
    };
    return (
        <Tabs activeKey={tab} onSelect={handleSelect} className=" justify-center tab-style-01 font-serif mb-[3.4rem] sm:mb-[-0.6rem] flex bg-transparent border-b-0 font-medium">
            <Tab className="tab-style-01 p-0 " eventKey={1} title={"Edit profile"}>

                <m.div className='items-center' {...{ ...fadeIn, transition: { duration: 0.9 }, viewport: { once: false } }}>
                    <Row className="justify-center items-center">
                        <Col lg={12} md={12} className="justify-center items-center md:mb-20 sm:mb-16">
                            <EditUserForm />
                        </Col>
                    </Row>
                </m.div>
            </Tab>
            <Tab className="tab-style-01" eventKey={2} title={"Change Password"}>
                <m.div className='items-center' {...{ ...fadeIn, transition: { duration: 0.9 }, viewport: { once: false } }}>
                    <Row className="items-center">
                        <Col lg={12} md={12} className="justify-center items-center md:mb-20 sm:mb-16">
                            <ForgotPass />

                        </Col>


                    </Row>
                </m.div>
            </Tab>
            {/* <Tab  className="tab-style-01 p-0 "  eventKey={3} title={"My Booking"}>

                <m.div className='row items-center' {...{ ...fadeIn, transition: { duration: 0.9 }, viewport: { once: false } }}>
                    <Row className="justify-center items-center">
                        <Col lg={12} md={12} className="md:mb-24 sm:mb-16 text-[18px] text-black">
                          <MyUserBooking/>
                        </Col>
                    </Row>
                </m.div>
            </Tab> */}
            <Tab className="tab-style-01 p-0 " eventKey={3} title={"My Jobs"}>

                <m.div className='items-center' {...{ ...fadeIn, transition: { duration: 0.9 }, viewport: { once: false } }}>
                    <Row className="justify-center items-center">
                        <Col lg={12} md={12} className="md:mb-24 sm:mb-16 text-[18px] text-black">
                            <MyJobs setTab={setTab} />
                        </Col>
                    </Row>
                </m.div>
            </Tab>
            <Tab className="tab-style-01 p-0 " eventKey={4} title={"Wishlist"}>

                <m.div className='items-center' {...{ ...fadeIn, transition: { duration: 0.9 }, viewport: { once: false } }}>
                    <Row className="justify-center items-center">
                        <Col lg={12} md={12} className="md:mb-24 sm:mb-16 text-[18px] text-black">
                            <Staffwishlist setTab={setTab} />
                        </Col>
                    </Row>
                </m.div>
            </Tab>
        </Tabs>
    )
}

UserAccountTab.defaultProps = {
    data: TabData01
}

UserAccountTab.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.exact({
            tabTitle: PropTypes.string,
            subtitle: PropTypes.string,
            title: PropTypes.string,
            img: PropTypes.string,
            content: PropTypes.string,
            buttonLink: PropTypes.string,
            buttonTitle: PropTypes.string,
            tabicons: PropTypes.string
        })
    ),
}


export default memo(UserAccountTab)