import React, { Suspense, useEffect, useState, lazy, useContext } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";
import GlobalContext from "./Context/Context";
import ScrollToTopButton from "./Components/ScrollToTop"
import UserLogin from "./Pages/Login/UserLogin";
import ChefLogin from "./Pages/Login/ChefLogin";
import UserSignUp from "./Pages/Sign up/UserSignUp";
import PrivacyPolicy from "./Pages/Legal/PrivacyPolicy";
import TermsOfService from "./Pages/Legal/TermsOfService";
import MyUserAccount from "./Pages/My Account/MyUserAccount";
import MyChefAccount from "./Pages/My Account/MyChefAccount";
import { AuthContext } from "./contextApi/authContext";
import ForgotPassUser from "./Pages/forgotPass";
import VerifyOtp from "./Pages/VerifyOtp";
import Home3 from "./Pages/Home/Home3";
import ChefSignUp from "./Pages/Sign up/ChefSignUp";
import MyJobs from "./Components/UserAccountTabContain/MyJobs";
import Jobs from "./Pages/Jobs/Jobs";
import Modal from "./Components/CustomPopup";
import { Col, Container, Row } from "react-bootstrap";
import CreateJobForm from "./Components/CreateJobForm/CreateJobForm";
const ChefDetails = lazy(() => import("./Pages/Portfolios/ChefDetails"))
const NotFoundPage = lazy(() => import("./Pages/404"))
const MaintenancePage = lazy(() => import("./Pages/AdditionalPages/MaintenancePage"))
const Chefs = lazy(() => import("./Pages/Chef/Chefs"))

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userData,setJobOpen , jobOpen } = useContext(AuthContext) 

  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();

  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  const PrivateRoute = ({ element: Element }) => {
    const isAuthenticated=localStorage.getItem('token')
    if (!isAuthenticated) {
      // If the user is not authenticated, redirect to the login page
      return <Navigate to="/login" />;
    }
  
    // If the user is authenticated, render the component
    return <Element />;
  };
  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App font-serif" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                {  localStorage.getItem('token')&&  <Route path="/login" element={<Navigate to="/" />} />}
                {  localStorage.getItem('token')&& localStorage.getItem('chef')&&  <Route path="/chef-login" element={<Navigate to="/" />} />}
                {  !localStorage.getItem('token')&& <Route path="/my-account" element={<Navigate to="/" />} />}

                  <Route path="/"   element={<Home3 style={{ "--base-color": "#000" }} />}/> 
                  <Route path="/jobs" element={<Jobs />} />
                  <Route path="/chef-login" element={<ChefLogin style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/chef-signup" element={<ChefSignUp style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/chefs"    element={<Chefs />}  />
                  <Route path="/login" element={<UserLogin style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/user-signup" element={<UserSignUp style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/terms-of-service" element={<TermsOfService />} />
                  <Route path="/chef-details" element={<ChefDetails style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/forgot-password" element={<ForgotPassUser style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/verify_otp" element={<VerifyOtp style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/my-account" element={
                    // userData && userData.login_type == 'chef'
                    localStorage.getItem("chef")
                     ?<MyChefAccount style={{ "--base-color": "#0038e3" }} />:<MyUserAccount style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/page/error-404" element={<NotFoundPage style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/page/maintenance" element={<MaintenancePage />} />
                  <Route path="*" element={<NotFoundPage />} />
        
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
          
        }
      </div>
      <div className="app">
                {jobOpen &&
                    <Modal bgClass={'bg-white'} title={'Create Job'} isOpen={() => { setJobOpen(true) }} onClose={() => { setJobOpen(false) }} >
                        <div className="text-left relative landscape:md:mt-[100px]" id="subscribe-popup">
                            <Container>
                                <Row className="justify-center">
                                    <Col className="px-4 py-3 bg-white overflow-hidden" xl='12' lg={12} sm={{ span: 11, order: 1 }} xs={{ span: 12, order: 2 }}>
                                        <CreateJobForm show={jobOpen} onHide={() => { setJobOpen(false) }} OpenPopup={() => { setJobOpen(true) }} />
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Modal>
                }
            </div>
    </GlobalContext.Provider>
  )
}

export default App;